/* eslint-disable react/no-unstable-nested-components */
import React, {useState, useEffect, forwardRef, Component, Fragment} from 'react';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import {toast} from 'react-toastify';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import Typography from '@mui/material/Typography';
import ChevronLeftSharpIcon from '@mui/icons-material/ChevronLeftSharp';
import CancelIcon from '@mui/icons-material/Cancel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import {
  HTTP_REQUEST_UKLOCATIONS,
  HTTP_REQUEST_WORLDLOCATIONS,
} from 'app/services/httpClient/gather.service';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import withRouter from 'app/utils/withRouter';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import chlogo from 'app/assets/chlogo.svg';
import GoogleIcon from '@mui/icons-material/Google';
import {FixedSizeList} from 'react-window';
import {Container, ToggleButtonGroup, ToggleButton, Select, MenuItem} from '@mui/material';

const {uksic} = require('app/constants/uksic');
const {COUNTRIESLIST} = require('app/constants/countriesList');
const {BUSINESSLIST} = require('app/constants/businessesList');
const {INITIALUK} = require('app/constants/initialUK');
const {ukcities} = require('app/constants/ukcities');

function mapStateToProps(state) {
  return {
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
  };
}

const LISTBOX_PADDING = 8; // Adjust as needed

const radiusoptions = [
  {miles: '+1 mile', km: 2, index: 1},
  {miles: '+5 miles', km: 8, index: 2},
  {miles: '+10 miles', km: 16, index: 3},
  {miles: '+25 miles', km: 40, index: 4},
  {miles: '+50 miles', km: 80, index: 5},
  {miles: '+100 miles', km: 161, index: 6},
  {miles: '+250 miles', km: 402, index: 7},
];

const ukradiusoptions = [
  // {miles: 'Only', m: 0, index: 0},
  {miles: '+1 mile', m: 1, index: 1},
  {miles: '+2 miles', m: 2, index: 2},
  {miles: '+5 miles', m: 5, index: 3},
  {miles: '+10 miles', m: 10, index: 4},
  {miles: '+25 miles', m: 25, index: 5},
];

// const ListboxComponent = React.forwardRef((props, ref) => {
//   // Extract relevant props
//   const { listoptions, onSelect, handleActivityChange, ...other } = props;

//   // Assuming 'options' is your array of data items
//   const itemCount = listoptions.length;
//   const itemSize = 48; // Or whatever your item size is

//   return (
//     <div
//       ref={ref}
//       {...other}
//       // onScroll={handleScroll}
//       style={{ maxHeight: '250px', overflow: 'auto' }}
//     >
//       <FixedSizeList
//         height={Math.min(250, itemCount * itemSize)}
//         width="100%"
//         itemSize={itemSize}
//         itemCount={itemCount}
//         itemData={{
//           items: listoptions, // The options to display
//           handleActivityChange, // The change handler
//         }}
//         onSelect={onSelect}
//         handleActivityChange={handleActivityChange}
//       >
//         {renderRow}
//       </FixedSizeList>
//     </div>
//   );
// });

// function renderRow(props) {
//   // Inside renderRow
//   const { index, style, data } = props;
//   const item = data.items[index]; // Access items array from itemData
//   const { handleActivityChange } = data; // Destructure handleActivityChange from itemData

//   const tooltipContent = item.id
//     ? 'Get results from the Companies House database to use filters like business size and incorporation date'
//     : 'Get results from Google including the website and Google ratings';

//   // Apply styles directly similar to what you used in renderOption
//   const itemStyles = {
//     display: 'flex',
//     alignItems: 'center',
//     textDecoration: 'none',
//     padding: '10px', // Example padding, adjust as needed
//     '&:hover': {
//       backgroundColor: '#ebebeb',
//       borderRadius: '32px',
//     },
//     cursor: 'pointer', // Make it obvious it's clickable
//   };

//   const textStyle = {
//     whiteSpace: 'nowrap',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     maxWidth: '250px', // Adjust the width as necessary for your design
//   };

//   // Handling click event on the row
//   const handleClick = () => {
//     console.log('Item clicked:', item);
//     handleActivityChange(null, item);
//     // Here, implement logic that should happen on click, e.g., selection
//   };

//   return (
//     <div style={style} key={item.key || index} onClick={handleClick}>
//       <Box component="li" sx={itemStyles} key={item.key || index}>
//         {item.id ? (
//           <Tooltip title={tooltipContent} interactive placement="bottom">
//             <span
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 marginRight: '10px',
//               }}
//             >
//               <img
//                 src={chlogo}
//                 alt="Icon"
//                 style={{ width: '14px', marginBottom: '-2px' }}
//               />
//             </span>
//           </Tooltip>
//         ) : (
//           <Tooltip title={tooltipContent} interactive placement="bottom">
//             <span
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 marginRight: '10px',
//               }}
//             >
//               <Search style={{ fontSize: '11px', marginBottom: '-1px' }} />
//             </span>
//           </Tooltip>
//         )}
//         <Tooltip title={item.Label} interactive placement="bottom">
//           <span style={textStyle}>{item.Label}</span>
//         </Tooltip>
//       </Box>
//     </div>
//   );
// }

// const ListboxComponent = React.forwardRef((props, ref) => {
//   const { listoptions, onSelect, handleActivityChange, ...other } = props;
//   const itemCount = listoptions.length;
//   const itemSize = 48; // Adjust based on your item size

//   return (
//     <div ref={ref} {...other} style={{ maxHeight: '250px', overflow: 'auto' }}>
//       <FixedSizeList
//         height={Math.min(250, itemCount * itemSize)}
//         // height="76vh"
//         width="100%"
//         itemSize={itemSize}
//         itemCount={itemCount}
//         itemData={{
//           items: listoptions,
//           handleActivityChange,
//         }}
//       >
//         {renderRow}
//       </FixedSizeList>
//     </div>
//   );
// });
// const ListboxComponent = forwardRef((props, ref) => {
//   const {listoptions, onSelect, handleActivityChange, ...other} = props;
//   const itemCount = listoptions.length;
//   const itemSize = 48; // Adjust based on your item size

//   let listHeight;
//   let setListHeight;

//   // State to hold the dynamic height
//   if (typeof window !== 'undefined') {
//     [listHeight, setListHeight] = useState(window.innerHeight * 0.76);
//   }

//   // Effect to update height on window resize
//   useEffect(() => {
//     if (typeof window !== 'undefined') {
//       const updateHeight = () => {
//         setListHeight(window.innerHeight * 0.76);
//       };

//       window.addEventListener('resize', updateHeight);

//       // Cleanup listener on component unmount
//       return () => window.removeEventListener('resize', updateHeight);
//     }
//   }, []);

//   return (
//     <div ref={ref} {...other} style={{maxHeight: '76vh', overflow: 'auto'}}>
//       <FixedSizeList
//         height={listHeight} // Use the state value here
//         width="100%"
//         itemSize={itemSize}
//         itemCount={itemCount}
//         itemData={{
//           items: listoptions,
//           handleActivityChange,
//         }}>
//         {renderRow}
//       </FixedSizeList>
//     </div>
//   );
// });
const ListboxComponent = forwardRef((props, ref) => {
  const {children, ...other} = props;

  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48; // Adjust based on your item size

  const height = Math.min(8, itemCount) * itemSize + 2 * LISTBOX_PADDING; // Adjust as needed

  return (
    <div ref={ref} {...other}>
      <FixedSizeList
        height={height}
        width="100%"
        itemData={itemData}
        itemCount={itemCount}
        itemSize={itemSize}
        overscanCount={5}>
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

function renderRow(props) {
  const {data, index, style} = props;
  const item = data[index];

  return React.cloneElement(item, {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

// function renderRow(props) {
//   const {index, style, data} = props;
//   const item = data.items[index];
//   const {handleActivityChange} = data;

//   const tooltipContent = item.id
//     ? 'Get licensed results from the Companies House database to use filters like business size and incorporation date'
//     : 'Get licensed results from Google including the website and Google ratings';

//   const textStyle = {
//     whiteSpace: 'nowrap',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     maxWidth: '250px', // Adjust the width as necessary for your design
//   };

//   // Apply styles directly similar to what you used in renderOption
//   const itemStyles = {
//     display: 'flex',
//     alignItems: 'center',
//     textDecoration: 'none',
//     padding: '10px', // Example padding, adjust as needed
//     '&:hover': {
//       backgroundColor: '#ebebeb',
//       borderRadius: '32px',
//     },
//     cursor: 'pointer', // Make it obvious it's clickable
//   };

//   // Handling click event on the row
//   const handleClick = () => {
//     // console.log('Item clicked:', item);
//     handleActivityChange(null, item); // Now this correctly identifies the item based on its scrolled-to position
//   };

//   return (
//     <div style={style}>
//       <Box
//         // component="li"
//         onClick={handleClick}
//         sx={itemStyles}
//         key={item.key || index}>
//         {item.id ? (
//           <Tooltip
//             PopperProps={{
//               style: {zIndex: 99999},
//             }}
//             title={tooltipContent}
//             interactive
//             placement="bottom">
//             <span
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 marginRight: '10px',
//               }}>
//               <img src={chlogo} alt="Icon" style={{width: '14px', marginBottom: '-2px'}} />
//             </span>
//           </Tooltip>
//         ) : (
//           <Tooltip
//             PopperProps={{
//               style: {zIndex: 99999},
//             }}
//             title={tooltipContent}
//             interactive
//             placement="bottom">
//             <span
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 marginRight: '10px',
//               }}>
//               <GoogleIcon style={{fontSize: '11px', marginBottom: '-1px'}} />
//             </span>
//           </Tooltip>
//         )}
//         <Tooltip
//           PopperProps={{
//             style: {zIndex: 99999},
//           }}
//           title={item.Label}
//           interactive
//           placement="bottom">
//           <span style={textStyle}>{item.Label}</span>
//         </Tooltip>
//       </Box>
//     </div>
//   );
// }

// function renderRow(props) {
//   const {index, style, data} = props;
//   const item = data[index]; // Assuming 'data' is the array passed to 'itemData'

//   console.log('RENDER ROW', item);

//   // Construct tooltip content based on the item
//   const tooltipContent = item.id
//     ? 'Get results from the Companies House database to use filters like business size and incorporation date'
//     : 'Get results from Google including the website and Google ratings';
//   const iconSrc = item.id ? chlogo : undefined; // Adjust logic as needed if using different icons

//   return (
//     <div style={style} key={item.key || index}>
//       <Box
//         component="li"
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           textDecoration: 'none',
//           '&:hover': {backgroundColor: '#ebebeb'},
//           '&[aria-selected="true"]': {backgroundColor: '#ebebeb'},
//         }}>
//         {iconSrc && (
//           <Tooltip title={tooltipContent} interactive placement="bottom">
//             <span style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
//               {item.id ? (
//                 <img src={chlogo} alt="Icon" style={{width: '14px', marginBottom: '-2px'}} />
//               ) : (
//                 <Search
//                   style={{
//                     fontSize: '11px',
//                     marginRight: '10px',
//                     marginBottom: '-1px',
//                   }}
//                 />
//               )}
//             </span>
//           </Tooltip>
//         )}
//         <span>{item.Label}</span>
//       </Box>
//     </div>
//   );
// }

class LeadsearchBar extends Component {
  constructor(props) {
    super(props);
    this.locationInputRef = React.createRef();
    this.worldLocationInputRef = React.createRef();
    this.activityInputRef = React.createRef();
    this.googleToggleButtonRef = React.createRef();
    this.companiesHouseToggleButtonRef = React.createRef();
    this.state = {
      profile: {},
      // profilestate: {},
      items: [],
      data: [],
      columns: [],
      selected: [],
      loading: true,
      pagenumber: undefined,
      analysinglead: undefined,
      selectOpen: false,
      mouseInListBox: false,
      opencountry: false,
      openlocation: false,
      openworldlocation: false,
      openradius: false,
      selectingOption: false,
      openactivity: false,
      hovered: undefined,
      worldlocvalue: '',
      infopayload: {},
      payload: this.props.payload ? this.props.payload : {},
      inputCountry: 'United Kingdom',
      ukInputValue: '',
      worldInputValue: '',
      country: {
        code: 'GB',
        label: 'United Kingdom',
        capital: 'London',
        phone: '44',
        location: {lat: 51.5074, lng: -0.1278},
      },
      ukloc: [],
      worldloc: [],
      location: undefined,
      radius: {},
      radiusInputValue: '',
      worldradius: {},
      worldRadiusInputValue: '',
      activity: this.props.activity ? this.props.activity : '',
      activityval: this.props.activityval ? this.props.activityval : '',
      activityInput: this.props.activityInput ? this.props.activityInput : undefined,
      displayedOptions: undefined,
      hasMoreOptions: true,
      next_page_token: undefined,
      showFlag: true,
      ignoreNextInputChange: false,
      powersearch: false,
      businessListType: 'both',
      searching: this.props.loading ? this.props.loading : false,
      dialogueOpen: false,
      activityInputBackup: undefined,
      ukInputValueBackup: undefined,
      clearedParams: [],
      toggle: 'Companies',
      openjobtitle: false,
      jobTitle: undefined,
      jobTitleOptions: [
        {label: 'Account Manager'},
        {label: 'Accountant'},
        {label: 'Actuary'},
        {label: 'Administrative Assistant'},
        {label: 'Advertising Manager'},
        {label: 'Aerospace Engineer'},
        {label: 'Analyst'},
        {label: 'Application Developer'},
        {label: 'Architect'},
        {label: 'Art Director'},
        {label: 'Assistant Manager'},
        {label: 'Attorney'},
        {label: 'Auditor'},
        {label: 'Bank Teller'},
        {label: 'Biomedical Engineer'},
        {label: 'Business Analyst'},
        {label: 'Business Development'},
        {label: 'Carpenter'},
        {label: 'Chief Executive Officer (CEO)'},
        {label: 'Chief Financial Officer (CFO)'},
        {label: 'Chief Information Officer (CIO)'},
        {label: 'Chief Marketing Officer (CMO)'},
        {label: 'Chief Operating Officer (COO)'},
        {label: 'Chief Technology Officer (CTO)'},
        {label: 'Civil Engineer'},
        {label: 'Client Service Manager'},
        {label: 'Clinical Research Coordinator'},
        {label: 'Clinical Research Scientist'},
        {label: 'Compliance Officer'},
        {label: 'Construction Manager'},
        {label: 'Content Manager'},
        {label: 'Content Strategist'},
        {label: 'Copywriter'},
        {label: 'Corporate Trainer'},
        {label: 'Creative Director'},
        {label: 'Customer Service Representative'},
        {label: 'Data Analyst'},
        {label: 'Data Engineer'},
        {label: 'Data Scientist'},
        {label: 'Database Administrator'},
        {label: 'Dentist'},
        {label: 'Desktop Support Specialist'},
        {label: 'DevOps Engineer'},
        {label: 'Digital Marketing Manager'},
        {label: 'Director'},
        {label: 'Director of Operations'},
        {label: 'Electrical Engineer'},
        {label: 'Elementary School Teacher'},
        {label: 'Engineer'},
        {label: 'Environmental Engineer'},
        {label: 'Event Coordinator'},
        {label: 'Executive Assistant'},
        {label: 'Financial Advisor'},
        {label: 'Financial Analyst'},
        {label: 'Financial Manager'},
        {label: 'Flight Attendant'},
        {label: 'Founder'},
        {label: 'Front End Developer'},
        {label: 'Full Stack Developer'},
        {label: 'Graphic Designer'},
        {label: 'Healthcare Administrator'},
        {label: 'Human Resources (HR) Manager'},
        {label: 'Human Resources (HR) Specialist'},
        {label: 'Information Security Analyst'},
        {label: 'IT Manager'},
        {label: 'IT Support Specialist'},
        {label: 'Java Developer'},
        {label: 'Journalist'},
        {label: 'Laboratory Technician'},
        {label: 'Lawyer'},
        {label: 'Legal Assistant'},
        {label: 'Logistics Coordinator'},
        {label: 'Machine Learning Engineer'},
        {label: 'Management Consultant'},
        {label: 'Marketing Analyst'},
        {label: 'Marketing Coordinator'},
        {label: 'Marketing Manager'},
        {label: 'Marketing Specialist'},
        {label: 'Mechanical Engineer'},
        {label: 'Medical Assistant'},
        {label: 'Medical Doctor'},
        {label: 'Medical Laboratory Technician'},
        {label: 'Mobile Developer'},
        {label: 'Network Administrator'},
        {label: 'Nurse'},
        {label: 'Nurse Practitioner'},
        {label: 'Occupational Therapist'},
        {label: 'Office Manager'},
        {label: 'Operations Manager'},
        {label: 'Optometrist'},
        {label: 'Owner'},
        {label: 'Paralegal'},
        {label: 'Partner'},
        {label: 'Pharmacist'},
        {label: 'Physical Therapist'},
        {label: 'Physician'},
        {label: 'Physician Assistant'},
        {label: 'Pilot'},
        {label: 'Procurement'},
        {label: 'Product Designer'},
        {label: 'Product Manager'},
        {label: 'Program Manager'},
        {label: 'Project Coordinator'},
        {label: 'Project Manager'},
        {label: 'Psychologist'},
        {label: 'Public Relations Manager'},
        {label: 'Quality Assurance (QA) Analyst'},
        {label: 'Real Estate Agent'},
        {label: 'Receptionist'},
        {label: 'Recruiter'},
        {label: 'Registered Nurse'},
        {label: 'Research Analyst'},
        {label: 'Sales Associate'},
        {label: 'Sales Manager'},
        {label: 'Sales Representative'},
        {label: 'School Counselor'},
        {label: 'Secretary'},
        {label: 'Security Analyst'},
        {label: 'Senior Software Engineer'},
        {label: 'Social Media Manager'},
        {label: 'Software Developer'},
        {label: 'Software Engineer'},
        {label: 'Solutions Architect'},
        {label: 'Speech-Language Pathologist'},
        {label: 'Statistician'},
        {label: 'Supply Chain Manager'},
        {label: 'Support Engineer'},
        {label: 'Surgeon'},
        {label: 'Systems Administrator'},
        {label: 'Systems Analyst'},
        {label: 'Teacher'},
        {label: 'Technical Support Specialist'},
        {label: 'Technical Writer'},
        {label: 'User Experience (UX) Designer'},
        {label: 'User Interface (UI) Designer'},
        {label: 'Veterinarian'},
        {label: 'Warehouse Manager'},
        {label: 'Web Developer'},
        {label: 'Web Designer'},
        {label: 'Writer'},
        {label: 'Zoologist'},
      ],
      selectedJobTitle: '',
    };
    this.handleKeyDownCapture = this.handleKeyDownCapture.bind(this);
    this.handleLabel = this.handleLabel.bind(this);
    this.handleActivityChange = this.handleActivityChange.bind(this);
    this.FetchWorldLocations = this.FetchWorldLocations.bind(this);
    this.FetchUKLocations = this.FetchUKLocations.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
    this.UpdateState = this.UpdateState.bind(this);
    this.FilterDialog = this.FilterDialog.bind(this);
    this.CountryAutocomplete = this.CountryAutocomplete.bind(this);
    this.LocationAutocomplete = this.LocationAutocomplete.bind(this);
    this.BusinessAutocomplete = this.BusinessAutocomplete.bind(this);
    this.HandleToggle = this.HandleToggle.bind(this);
    // this.handleBusinessListToggle = this.handleBusinessListToggle.bind(this);
  }

  async componentDidMount() {
    // console.log('LEADSEARCH BAR INITIAL', this.props);
    if (this.props.toggle) {
      this.setState({toggle: this.props.toggle});
    }
    if (this.props.location && this.props.location.state) {
      this.setState({...this.props.location.state});
    }
    if (this.props.search && this.props.search.c) {
      this.FetchWorldLocations({
        country_code: this.props.search.c,
        limit: 100,
        open: false,
      });
    }
    if (this.props.search && this.props.search.rad) {
      const selectedOption = ukradiusoptions.find(
        (option) => option.m.toString() === this.props.search.rad
      );
      if (selectedOption) {
        this.setState({
          radius: selectedOption,
          radiusInputValue: selectedOption.miles,
        });
      }
    }
    if (this.props.search && this.props.search.l) {
      const location = await this.FetchUKLocations({
        query: {Postcode: this.props.search.l},
        limit: 100,
      });
      if (location) {
        // console.log('TESTING', location);
        this.setState({
          uklocvalue: location,
          // ukloc: [location],
          ukInputValue: location.Area,
        });
      }
    }
    if (!this.state.ukloc || (this.state.ukloc && this.state.ukloc.length <= 0)) {
      // this.FetchUKLocations({limit: 100}, false);
      this.setState({ukloc: INITIALUK});
      // this.FetchUKLocations({
      //   query: {},
      //   limit: 100,
      // });
    }
    if (this.props.search.w) {
      HTTP_REQUEST_WORLDLOCATIONS({
        geoname_id: this.props.search.w,
        limit: 1,
      })
        .then((response) => response.json())
        .then((response) => {
          const worldlocation = response.response.response;
          // console.log('FETCH WORLD LOCATIONS CDM RESPONSE', worldlocation);
          if (worldlocation.length > 0) {
            const value = worldlocation.length > 0 ? worldlocation[0] : {};
            this.setState(
              {
                payload: {
                  ...this.state.payload,
                  geoname_id: `${value.geoname_id}`,
                  country: `${value.country_code}`,
                  cords: `${value.country_code}`,
                },
                location: value.name,
                worldlocvalue: value,
                worldInputValue: value.name,

                // worldloc: worldlocation,
                // locationloading: false,
                // initialLoad: false,
                // openworldlocation: true, // Set openworldlocation to true here, once locations are fetched
              },
              () => {
                // If focusing on the world location input is desired immediately after opening:
                if (typeof this.props.UpdateState === 'function') {
                  this.props.UpdateState({
                    // worldloc: worldlocation,
                    // locationloading: false,
                    // initialLoad: false,
                    // openworldlocation: true, // Set openworldlocation to true here, once locations are fetched
                  });
                }
                this.worldLocationInputRef.current?.focus();
              }
            );
          }
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (this.props.search.ac) {
      const activity = decodeURI(this.props.search.ac);
      this.setState({
        activityval: {Label: activity},
        activityInput: activity,
      });
    }
    this.setState({dialogueOpen: false});
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the loading prop has changed
    if (
      this.props.country &&
      this.state.country &&
      this.props.country.code !== this.state.country.code
    ) {
      this.UpdateState({
        country: this.props.country,
      });
    }
    if (this.props.powersearch !== this.state.powersearch) {
      this.UpdateState({
        powersearch: this.props.powersearch,
      });
    }
    if (prevProps.loading !== this.props.loading) {
      // console.log('CHANGE LOADING', this.props.loading, prevProps.loading);
      this.UpdateState({
        searching: this.props.loading,
        loading: this.props.loading,
      });
    }
    if (prevProps.payload !== this.props.payload) {
      this.UpdateState({
        payload: {
          ...this.state.payload,
          ...this.props.payload,
        },
      });
    }
    if (prevProps.activityval !== this.props.activityval) {
      this.UpdateState({
        activityval: this.props.activityval,
      });
    }
    if (prevProps.activityInput !== this.props.activityInput) {
      this.UpdateState({
        activityInput: this.props.activityInput,
      });
    }
    if (prevProps.activity !== this.props.activity) {
      this.UpdateState({
        activity: this.props.activity,
      });
    }
    if (
      prevProps.loading !== this.state.searching ||
      (this.props.loading && this.props.loading !== this.state.searching)
    ) {
      // console.log('CHANGE LOADING', this.props.loading, prevProps.loading);
      // The loading prop has changed
      // Update the state based on the new value of loading prop
      this.UpdateState({
        searching: this.props.loading,
      });
    }
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
  }

  handleKeyDownCapture = (e) => {
    if (e.key === 'Enter' && !this.state.mouseInListBox) {
      e.stopPropagation();
      e.target?.blur(); // close the dropdown
    }
  };

  // UpdateState(state) {
  //   this.setState(state);
  // }

  CountryAutocomplete({isMobile}) {
    const paperStyle = isMobile
      ? {
          borderRadius: '25px 25px 0px 0px',
          // marginTop: '-69px',
          height: '90vh',
          padding: '8px',
          width: '100vw', // '114%' is not responsive, it's better to use calc with 100% and adjust the margins
          // borderRadius: '32px',
          // marginTop: '12px',
          // padding: '8px',
          // // width: '100vw',
        }
      : {
          borderRadius: '32px',
          marginTop: '12px',
          padding: '8px',
          width: '100%',
        };

    const autocompleteStyle = isMobile
      ? {
          paddingLeft: '0px',
          // border: '3px solid red',
          backgroundColor: this.state.opencountry ? '#ffffff' : 'inherit',
          minWidth: '100%',
          borderRadius: '24px',
          // boxShadow: this.state.opencountry
          //   ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
          //   : 'none',
        }
      : {
          paddingLeft: '0px',
          minWidth: '33%',
          backgroundColor: this.state.opencountry ? '#ffffff' : 'inherit',
          borderRadius: '32px',
          boxShadow: this.state.opencountry
            ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
            : 'none',
        };

    // const paperStyle = {
    //   borderRadius: '32px',
    //   marginTop: '12px',
    //   padding: '8px',
    //   width: '170%',
    // };

    const listBoxStyle = isMobile
      ? {
          maxHeight: '85vh', // Adjust to desired view height for mobile
          // overflow: 'auto', // Add overflow property to enable scrolling
          minWidth: '100%',
          paddingLeft: '3px',
        }
      : {
          maxHeight: '40vh', // Adjust to desired view height for desktop
          overflow: 'auto', // Add overflow property to enable scrolling
        };
    return (
      <Autocomplete
        id="country-select"
        options={COUNTRIESLIST}
        disableClearable
        // autoHighlight
        // autoSelect
        // freeSolo
        open={this.state.opencountry}
        onMouseEnter={() => this.setState({hovered: 'country'})}
        onMouseLeave={() => this.setState({hovered: undefined})}
        onOpen={() =>
          this.setState({
            opencountry: true,
            openlocation: false,
            openworldlocation: false,
            openradius: false,
            openactivity: false,
            selectingOption: true,
          })
        }
        onClose={() =>
          this.setState({
            opencountry: false,
            selectingOption: false,
          })
        }
        onInputChange={(event, newInputValue) => {
          // this.props.UpdateState({inputCountry: newInputValue, initialLoad: true});
          if (typeof this.props.UpdateState === 'function') {
            this.props.UpdateState({inputCountry: newInputValue});
          }
          this.setState({inputCountry: newInputValue});
        }}
        style={autocompleteStyle}
        value={this.props.country ? this.props.country : this.state.country}
        // PaperComponent={({ children }) => (
        //   <Paper style={paperStyle} elevation={1}>
        //     {children}
        //   </Paper>
        // )}
        componentsProps={{
          paper: {
            style: paperStyle,
            elevation: isMobile ? 0 : 1,
          },
        }}
        onKeyDownCapture={this.handleKeyDownCapture}
        ListboxProps={{
          onMouseEnter: () => this.setState({mouseInListBox: true}),
          onMouseLeave: () => this.setState({mouseInListBox: false}),
          style: listBoxStyle,
          sx: {
            '&& li.Mui-focused': {
              bgcolor: !this.state.mouseInListBox ? 'transparent' : 'transparent',
            },
          },
        }}
        onChange={(e, v) => {
          // console.log('Country Selected:', v);
          if (v) {
            const isUK = v.code === 'GB';
            // console.log('Country Selected 1:', v.label, 'Is UK?', isUK);
            this.setState(
              {
                selectingOption: true,
                openlocation: !isMobile && isUK,
                openworldlocation: !isMobile && !isUK,
                inputCountry: v.label,
                worldInputValue: isUK ? '' : v.capital,
                ukInputValue: '',
                worldlocvalue: {
                  name: v.capital,
                  country_code: isUK ? 'UK' : v.code,
                  coordinates: {
                    lat: v.location.lat,
                    lon: v.location.lng,
                  },
                },
                infopayload: {
                  ...this.state.infopayload,
                  country: v,
                },
                country: v,
                location: v.capital,
                worldloc: [],
                locationloading: true,
                next_page_token: undefined,
                showFlag: true,
                powersearch: !isUK,
                // worldlocvalue: '',
              },
              () => {
                if (typeof this.props.UpdateState === 'function') {
                  this.props.UpdateState({
                    infopayload: {
                      ...this.props.infopayload,
                      country: v,
                    },
                    worldlocvalue: {
                      name: v.capital,
                      country_code: isUK ? 'UK' : v.code,
                      coordinates: {
                        lat: v.location.lat,
                        lon: v.location.lng,
                      },
                    },
                    inputCountry: v.label,
                    country: v,
                    worldloc: [],
                    location: v.capital,
                    next_page_token: undefined,
                    showFlag: true,
                    // powersearch: !isUK,
                    // worldlocvalue: '',
                  });
                }

                // Fetch UK or World locations based on the selection
                if (isUK) {
                  // this.FetchUKLocations({limit: 100}, true);
                  if (typeof this.props.UpdateState === 'function') {
                    this.props.UpdateState({
                      powersearch: false,
                    });
                  }
                  this.FetchUKLocations({
                    query: {},
                    limit: 100,
                  });
                } else {
                  // console.log(
                  //   'COUNTRY SELECT FETCH WORLD',
                  //   v,
                  //   this.state.country,
                  //   this.state.country
                  // );
                  if (typeof this.props.UpdateState === 'function') {
                    this.props.UpdateState({powersearch: true});
                  }
                  this.setState({powersearch: true, opencountry: false});
                  this.FetchWorldLocations({
                    country_code: v.code,
                    limit: 100,
                    open: !isMobile,
                  });
                }
              }
            );
          } else {
            // console.log('No country selected');
            this.setState({
              opencountry: false,
              openlocation: false,
              openworldlocation: false,
            });
          }
        }}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, {selected}) => (
          <Box
            // component="li"
            key={option.code}
            style={{boxShadow: 'none'}}
            sx={{
              fontSize: '0.9rem',
              '&:hover': {
                backgroundColor: '#ebebeb !important',
                borderRadius: '32px !important',
              },
              '&[aria-selected="true"]': {
                backgroundColor: '#ebebeb !important', // Apply the same background color as hover
                borderRadius: '32px !important', // Apply the same border radius as hover
              },
              // marginTop: '18px',
              display: 'flex',
              justifyContent: 'flex-start',
              '& > span': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis', // This will add an ellipsis if the text is too long
              },
              alignItems: 'center',
              textDecoration: selected ? 'underline' : 'none',
              '& > img': {
                marginLeft: '3px',
                marginRight: '9px',
                flexShrink: 0,
              },
            }}
            {...props}>
            {/* <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            /> */}
            {option.code && (
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
            )}
            <span>{option.label}</span>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={isMobile ? '' : 'Country'}
            // label="Country"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                paddingRight: '20px',
                paddingTop: '23px',
              },
              sx: {
                '.MuiInputBase-input': {
                  cursor: 'text', // Change the cursor for the input text
                },
                '.MuiOutlinedInput-input': {
                  cursor: 'text !important', // Ensure consistency across different MUI input classes
                  fontSize: '0.9rem !important',
                  '&:hover': {
                    cursor: 'text !important',
                  },
                },
                '.MuiOutlinedInput-root': {
                  cursor: 'text', // Ensure consistency across different MUI input classes
                },
                // Apply additional styles here if needed
              },
              startAdornment: (
                <>
                  {this.state.showFlag &&
                  this.state.country &&
                  this.state.inputCountry === this.state.country.label ? (
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${this.state.country.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${this.state.country.code.toLowerCase()}.png 2x`}
                      alt=""
                      style={{
                        marginLeft: isMobile ? '21px' : '18px',
                        marginRight: '4px',
                        marginTop: '1px',
                        cursor: 'pointer !important',
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        marginLeft: '8px',
                        // width: '14px',
                        marginRight: '4px',
                        marginTop: '1px',
                        cursor: 'pointer !important',
                      }}
                    />
                  )}
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: null,
            }}
            sx={{
              '& fieldset': {border: 'none'},
              '& .MuiOutlinedInput-root': {
                border: 'none',
                borderRadius: '32px', // Apply rounded corners
                height: '66px',
                cursor: 'pointer !important',
                // paddingBottom: '0px',
                borderColor: 'transparent',
                '&:hover': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent', // Optionally hide the border on hover
                  },
                  backgroundColor: this.state.opencountry
                    ? 'white'
                    : this.state.openlocation ||
                      this.state.openradius ||
                      this.state.openworldlocation ||
                      this.state.openactivity
                    ? '#dddddd'
                    : '#ebebeb',
                  cursor: 'pointer !important',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent', // Optionally hide the border when focused
                  cursor: 'pointer !important',
                },
              },
              // Move the label position
              '& .MuiInputLabel-outlined': {
                transform: 'translate(26px, 13px) scale(1)', // Adjust x, y values
                fontWeight: '600',
                fontSize: '16.2px',
                color: '#000000',
                cursor: 'pointer !important',
                // When the label is shrunk (after clicking on the TextField)
                '&.MuiInputLabel-shrink': {
                  cursor: 'pointer !important',
                  transform: 'translate(26px, 13px) scale(0.75)', // Adjust x, y values and scale to match the size
                },
                pointerEvents: 'none',
              },
            }}
          />
        )}
      />
    );
  }

  LocationAutocomplete({isMobile}) {
    const paperStyle = isMobile
      ? {
          borderRadius: '25px 25px 0px 0px',
          // marginTop: '-69px',
          height: '90vh',
          // marginLeft: '-3px',
          padding: '8px',
          width: '100vw', // '114%' is not responsive, it's better to use calc with 100% and adjust the margins
          // borderRadius: '32px',
          // marginTop: '12px',
          // padding: '8px',
          // // width: '100vw',
        }
      : {
          borderRadius: '32px',
          marginTop: '12px',
          padding: '8px',
          width: '100%',
        };

    const autocompleteStyle = isMobile
      ? {
          paddingLeft: '0px',
          // border: '3px solid red',
          backgroundColor:
            this.state.openlocation || this.state.openworldlocation ? '#ffffff' : 'inherit',
          minWidth: '100%',
          borderRadius: '24px',
          // boxShadow: this.state.opencountry
          //   ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
          //   : 'none',
        }
      : {
          paddingLeft: '0px',
          minWidth: this.state.toggle === 'Companies' ? '66%' : '100%',
          backgroundColor:
            this.state.openlocation || this.state.openworldlocation ? '#ffffff' : 'inherit',
          borderRadius: '32px',
          boxShadow:
            this.state.openlocation || this.state.openworldlocation
              ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
              : 'none',
        };

    // const paperStyle = {
    //   borderRadius: '32px',
    //   marginTop: '12px',
    //   padding: '8px',
    //   width: '170%',
    // };

    const listBoxStyle = isMobile
      ? {
          maxHeight: '85vh', // Adjust to desired view height for mobile
          // overflow: 'auto', // Add overflow property to enable scrolling
          minWidth: '100%',
          paddingLeft: '3px',
        }
      : {
          maxHeight: '40vh', // Adjust to desired view height for desktop
          overflow: 'auto', // Add overflow property to enable scrolling
        };

    const {ukInputValue, ukInputValueBackup, uklocvalue, ukloc} = this.state;

    return this.state.inputCountry === 'United Kingdom' ? (
      <Autocomplete
        // disablePortal
        id="leadsearch-location"
        loading={this.state.locationloading}
        open={this.state.openlocation}
        inputValue={this.state.ukInputValue}
        value={this.state.uklocvalue || {}}
        onOpen={(event) => {
          event.stopPropagation();
          this.setState({
            openlocation: true,
            opencountry: false,
            openworldlocation: false,
            openradius: false,
            openactivity: false,
            openjobtitle: false,
            selectingOption: true,
            ukInputValueBackup: this.state.ukInputValue,
            ukInputValue: '',
          });
        }}
        onBlur={(event) => {
          const currentInput = event.target.value;

          // If user leaves the field empty, restore the backup.
          if (!currentInput) {
            this.setState({
              ukInputValue: ukInputValueBackup,
              ukInputValueBackup: '',
            });
            return;
          }

          // If user typed something but never selected a valid option from the list
          if ((!uklocvalue || !uklocvalue.Area) && currentInput && this.props.toggle !== 'People') {
            toast.info('Select a location from the list to include it in your search.');
            this.setState({
              ukInputValue: ukInputValueBackup,
              ukInputValueBackup: '',
            });
          }
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'input') {
            this.setState({
              ukInputValue: newInputValue,
              inputValueBackup: newInputValue,
              locationloading: true,
            });
            if (newInputValue.length > 1) {
              this.FetchUKLocations({
                query: newInputValue,
                limit: 100,
              });
            }
          }
        }}
        onChange={(e, value) => {
          // console.log('ON CHANGE UK FEtCH', value);
          if (value !== null) {
            this.setState(
              {
                openlocation: false,
                location: value.Postcode,
                uklocvalue: value,
                ukInputValue: value.Area,
                inputValueBackup: '',
                payload: {
                  ...this.state.payload,
                  Area: `${value.Postcode}`,
                },
                openactivity:
                  this.state.activityval && !this.state.activityval.Label && !this.props.isMobile,
              },
              () => {
                if (
                  this.state.activityval &&
                  this.state.activityval.Label &&
                  this.activityInputRef.current
                ) {
                  this.activityInputRef.current.focus();
                }
              }
            );
          } else {
            if (this.state.uklocvalue && this.state.uklocvalue.length !== 0) {
              this.setState({
                location: undefined,
                radius: '',
                searchbar: false,
                searchval: '',
                page: 1,
                uklocvalue: '',
                inputValue: this.state.inputValueBackup,
                payload: {
                  ...this.state.payload,
                },
              });
            }
          }
        }}
        // onBlur={(event) => {
        //   if (
        //     !event.target.value ||
        //     !this.state.uklocvalue ||
        //     !this.state.uklocvalue.Area ||
        //     (this.state.uklocvalue &&
        //       this.state.uklocvalue.Area &&
        //       this.state.ukInputValueBackup &&
        //       this.state.uklocvalue.Area !== this.state.ukInputValueBackup) ||
        //     (this.state.ukInputValue &&
        //       this.state.uklocvalue &&
        //       this.state.uklocvalue.Area !== this.state.ukInputValue)
        //   ) {
        //     console.log('FIRST ONE');
        //     // Show a toast notification
        //     if (
        //       (this.props.toggle !== 'People' &&
        //         this.state.ukInputValue &&
        //         (!this.state.ukInputValue ||
        //           !this.state.uklocvalue ||
        //           !this.state.uklocvalue.Area)) ||
        //       (this.state.ukInputValue &&
        //         this.state.uklocvalue &&
        //         this.state.uklocvalue.Area !== this.state.ukInputValue)
        //     ) {
        //       console.log('FIRST ONE IF');
        //       this.setState({ukInputValue: this.state.ukInputValueBackup, ukInputValueBackup: ''});
        //       toast.info('Select a location from the list to include it in your search.');
        //     } else {
        //       console.log('FIRST ONE ELSE');
        //       this.setState({ukInputValue: this.state.ukInputValueBackup, ukInputValueBackup: ''});
        //     }
        //   }

        //   if (this.state.ukInputValue && this.state.ukInputValue.length === 0) {
        //     console.log('SECOND ONE')
        //     // toast.info('Select a location from the list to include it in your search HELLO.');
        //     this.setState({ukInputValue: this.state.ukInputValueBackup});
        //   }
        // }}
        // onInputChange={(event, newInputValue, reason) => {
        //   if (reason === 'input') {
        //     this.setState({
        //       ukInputValue: newInputValue,
        //       inputValueBackup: newInputValue,
        //       locationloading: true,
        //     });
        //     if (newInputValue.length > 1) {
        //       this.FetchUKLocations({
        //         query: newInputValue,
        //         limit: 100,
        //       });
        //     }
        //   }
        // }}
        // onChange={(e, value) => {
        //   console.log('ON CHANGE UK FEtCH', value);
        //   if (value !== null) {
        //     this.setState(
        //       {
        //         openlocation: false,
        //         location: value.Postcode,
        //         uklocvalue: value,
        //         ukInputValue: value.Area,
        //         inputValueBackup: '',
        //         payload: {
        //           ...this.state.payload,
        //           Area: `${value.Postcode}`,
        //         },
        //         openactivity: this.state.activityval && !this.state.activityval.Label && !isMobile,
        //       },
        //       () => {
        //         if (
        //           this.state.activityval &&
        //           this.state.activityval.Label &&
        //           this.activityInputRef.current
        //         ) {
        //           this.activityInputRef.current.focus();
        //         }
        //       }
        //     );
        //   } else {
        //     if (this.state.uklocvalue && this.state.uklocvalue.length !== 0) {
        //       this.setState({
        //         location: undefined,
        //         radius: '',
        //         searchbar: false,
        //         searchval: '',
        //         page: 1,
        //         uklocvalue: '',
        //         inputValue: this.state.inputValueBackup,
        //         payload: {
        //           ...this.state.payload,
        //         },
        //       });
        //     }
        //   }
        // }}
        onMouseEnter={() => this.setState({hovered: 'location'})}
        onMouseLeave={() => this.setState({hovered: undefined})}
        options={[...ukcities, ...this.state.ukloc]}
        loadingText="Loading..."
        getOptionLabel={(option) =>
          option.Area
            ? option.Area === 'Non-geographic'
              ? `${option.Region}, ${option.Postcode}`
              : `${option.Area} - ${option.Region}, ${option.Postcode}`
            : ''
        }
        isOptionEqualToValue={(option, value) => option.Postcode === value.Postcode}
        ListboxProps={{
          // onScroll: (event) => {
          //   const listboxNode = event.currentTarget;
          //   if (
          //     listboxNode.scrollTop + listboxNode.clientHeight >=
          //     listboxNode.scrollHeight - 10
          //   ) {
          //     // A little threshold before the very bottom
          //     console.log('REACHED THE BOTTOM');
          //     const savedScrollPosition = this.captureScrollPosition();

          //     // Calculate the new skip value
          //     const newSkip = this.state.ukloc.length;

          //     this.FetchUKLocations(
          //       {
          //         query: this.state.ukInputValue,
          //         limit: 100,
          //         skip: newSkip,
          //       },
          //       true
          //     ).then(() => {
          //       this.restoreScrollPosition(savedScrollPosition);
          //     });
          //   }
          // },
          onMouseEnter: () => this.setState({mouseInListBox: true}),
          onMouseLeave: () => this.setState({mouseInListBox: false}),
          style: listBoxStyle,
          sx: {
            '&& li.Mui-focused': {
              bgcolor: !this.state.mouseInListBox ? 'transparent' : 'transparent',
            },
          },
        }}
        style={autocompleteStyle}
        // style={{
        //   width: '59%',
        //   backgroundColor: this.state.openlocation ? '#ffffff' : 'inherit',
        //   borderRadius: '32px',
        //   boxShadow: this.state.openlocation
        //     ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
        //     : 'none',
        // }} // Set width to 66% of the parent container
        // PaperComponent={({children}) => (
        //   <Paper
        //     style={{
        //       borderRadius: '32px',
        //       marginTop: '12px',
        //       padding: '8px',
        //       width: '170%',
        //     }}
        //     elevation={1}>
        //     {children}
        //   </Paper>
        // )}
        componentsProps={{
          paper: {
            style: paperStyle,
            elevation: isMobile ? 0 : 1,
          },
        }}
        renderOption={(props, option, {selected}) => (
          <Box
            // component="li"
            style={{boxShadow: 'none'}}
            sx={{
              '&:hover': {
                backgroundColor: '#ebebeb !important',
                borderRadius: '32px !important',
              },
              '&[aria-selected="true"]': {
                backgroundColor: '#ebebeb !important', // Apply the same background color as hover
                borderRadius: '32px !important', // Apply the same border radius as hover
              },
              // marginTop: '18px',
              display: 'flex',
              justifyContent: 'flex-start !important',
              // '& > span': {
              //   whiteSpace: 'nowrap',
              //   overflow: 'hidden',
              //   textOverflow: 'ellipsis', // This will add an ellipsis if the text is too long
              // },
              alignItems: 'center',
              textDecoration: selected ? 'underline' : 'none',
              '& > img': {
                marginRight: '5px',
                flexShrink: 0,
              },
            }}
            {...props}>
            <span>
              {option.Area
                ? option.Area === 'Non-geographic'
                  ? `${option.Region}, ${option.Postcode}`
                  : `${option.Area} - ${option.Region}, ${option.Postcode}`
                : ''}
            </span>
          </Box>
        )}
        renderInput={(params) => (
          <div style={{position: 'relative'}}>
            <TextField
              {...params}
              // label="Location"
              label="Location"
              // label={
              //   <div style={{display: 'flex', alignItems: 'center'}}>
              //     <div>Location</div>
              //     {this.state.ukInputValue && (
              //       <div>
              //         <IconButton
              //           sx={{
              //             padding: '0px 8px',
              //             // '&:hover': {
              //             //   color: '#f40057 !important',
              //             // },
              //           }}
              //           onClick={() => this.setState({ukInputValue: ''})}>
              //           <CancelIcon
              //             sx={{
              //               fontSize: '19px',
              //               color: 'inherit',
              //             }}
              //           />
              //         </IconButton>
              //       </div>
              //     )}
              //   </div>
              // }
              inputRef={this.locationInputRef}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: true,
              }}
              variant="outlined"
              placeholder="Choose location"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style: {
                  paddingRight: 0,
                  paddingLeft: '20px',
                  paddingTop: '23px',
                },
                endAdornment: null,
                inputProps: {
                  ...params.inputProps,
                  style: {fontSize: '0.9rem'}, // Increase the font size for the placeholder
                },
              }}
              sx={{
                '& fieldset': {border: 'none'},
                '& .MuiOutlinedInput-root': {
                  border: 'none',
                  borderRadius: '32px',
                  height: '66px',
                  cursor: 'pointer !important',
                  borderColor: 'transparent',
                  '&:hover': {
                    backgroundColor: this.state.openlocation
                      ? 'white'
                      : this.state.opencountry ||
                        this.state.openradius ||
                        this.state.openworldlocation ||
                        this.state.openactivity
                      ? '#dddddd'
                      : '#ebebeb',
                    cursor: 'pointer !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    cursor: 'pointer !important',
                  },
                },
                '& .MuiInputLabel-outlined': {
                  transform: 'translate(26px, 13px) scale(1)',
                  fontWeight: '600',
                  fontSize: '16.2px',
                  color: '#000000',
                  cursor: 'pointer !important',
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(26px, 13px) scale(0.75)',
                    cursor: 'pointer !important',
                  },
                  pointerEvents: 'none',
                },
              }}
            />
            {this.state.ukInputValue && (
              <Tooltip title="Clear location" placement="top">
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: '76px',
                    top: '32%',
                    fontSize: '12px',
                    transform: 'translateY(-50%)',
                    padding: '0px 8px',
                    '&:hover': {
                      color: '#f40057',
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => {
                    this.setState((prevState) => ({
                      location: undefined,
                      radius: '',
                      searchbar: false,
                      searchval: '',
                      page: 1,
                      uklocvalue: '',
                      ukInputValueBackup: '',
                      ukloc: INITIALUK,
                      inputValue: '',
                      ukInputValue: '',
                      payload: (() => {
                        const newPayload = {...prevState.payload};
                        delete newPayload.Area;
                        return newPayload;
                      })(),
                      clearedParams: [...prevState.clearedParams, 'l'],
                    }));
                  }}>
                  <CancelIcon
                    sx={{
                      fontSize: '12px !important',
                      color: 'inherit',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      />
    ) : (
      <Autocomplete
        // disablePortal
        id="leadsearch-world-location"
        open={this.state.openworldlocation}
        value={
          this.state.worldlocvalue && Object.keys(this.state.worldlocvalue).length !== 0
            ? this.state.worldlocvalue
            : ''
        }
        inputValue={this.state.worldInputValue}
        onOpen={() => {
          // When opening, if desired, fetch locations here or reset the input value
          event.stopPropagation();
          this.setState({
            openworldlocation: true,
            openlocation: false,
            opencountry: false,
            openjobtitle: false,
            openradius: false,
            openactivity: false,
            worldlocvalue: '',
            selectingOption: true,
            ignoreNextInputChange: false, // Reset the flag when opening
          });
          // Optionally, fetch locations here if you want to repopulate the list every time it's opened
        }}
        onMouseEnter={() => this.setState({hovered: 'worldlocation'})}
        onMouseLeave={() => this.setState({hovered: undefined})}
        onClose={(event) => {
          if (event) event.stopPropagation();
          this.setState({
            openworldlocation: false,
            selectingOption: false,
          });
        }}
        loading={this.state.locationloading}
        onChange={(e, value) => {
          // console.log('ON CHANge WORLD LOCATION', value);
          if (value !== null) {
            this.setState(
              {
                openworldlocation: false,
                location: value.name,
                worldlocvalue: value,
                worldInputValue: value.name,
                openactivity: !isMobile,
                radius: {label: 'Only', value: 0},
                searchbar: false,
                searchval: '',
                next_page_token: undefined,
                page: 1,
                payload: {
                  ...this.state.payload,
                  geoname_id: `${value.geoname_id}`,
                  country: `${value.country_code}`,
                  cords: `${value.country_code}`,
                },
                ignoreNextInputChange: true, // Set the flag to ignore the next input change
              },
              () => {
                if (this.activityInputRef.current) {
                  this.activityInputRef.current.focus();
                }
              }
            );
          } else {
            // Handle case where value is null
            if (this.state.uklocvalue && this.state.uklocvalue.length !== 0) {
              this.setState(
                {
                  openworldlocation: false,
                  worldInputValue: '',
                  location: undefined,
                  radius: '',
                  searchbar: false,
                  searchval: '',
                  page: 1,
                  worldlocvalue: '',
                  payload: {
                    ...this.state.payload,
                  },
                  ignoreNextInputChange: false,
                },
                () => {
                  this.setState({openactivity: true}, () => {
                    if (this.activityInputRef.current) {
                      this.activityInputRef.current.focus();
                    }
                  });
                }
              );
            }
            // Additional logic here
          }
        }}
        ListboxProps={{
          onMouseEnter: () => this.setState({mouseInListBox: true}),
          onMouseLeave: () => this.setState({mouseInListBox: false}),
          style: listBoxStyle,
          sx: {
            '&& li.Mui-focused': {
              bgcolor: !this.state.mouseInListBox ? 'transparent' : 'transparent',
            },
          },
        }}
        // onInputChange={(event, newInputValue) => {
        //   console.log('WORLD INPUT CHANGE', newInputValue, this.state.worldlocvalue);
        //   if (!this.state.initialLoad && newInputValue?.length > 1) {
        //     this.FetchWorldLocations({
        //       country_code: this.state.country.code,
        //       limit: 100,
        //       name: newInputValue,
        //     });
        //   }
        // }}
        onInputChange={(event, newInputValue, reason) => {
          // console.log(
          //   'World INPUT CHANGE',
          //   this.state.worldInputValue,
          //   newInputValue,
          //   this.state.worldlocvalue
          // );
          if (reason === 'input') {
            this.setState({
              worldInputValue: newInputValue,
              locationloading: true,
            });
            if (newInputValue.length > 1) {
              this.FetchWorldLocations({
                country_code:
                  this.props.country && this.props.country.code
                    ? this.props.country.code
                    : this.state.country.code,
                limit: 100,
                name: newInputValue,
                open: !isMobile,
              });
            }
          }
        }}
        // onInputChange={(event, newInputValue, reason) => {
        //   console.log('WORLD INPUT CHANGE', reason, newInputValue, this.state.worldlocvalue);
        //   if (
        //     !this.state.ignoreNextInputChange &&
        //     reason === 'input' &&
        //     newInputValue?.length > 1
        //   ) {
        //     this.FetchWorldLocations({
        //       country_code: this.state.country.code,
        //       limit: 100,
        //       name: newInputValue,
        //     });
        //   } else if (reason === 'reset') {
        //     // this.setState({ignoreNextInputChange: false}); // Reset the flag if the input change is due to a reset
        //   }
        // }}
        // style={{
        //   paddingLeft: '0px',
        //   width: '59%',
        //   backgroundColor: this.state.openworldlocation ? '#ffffff' : 'inherit',
        //   borderRadius: '32px',
        //   boxShadow: this.state.openworldlocation
        //     ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
        //     : 'none',
        // }}
        style={autocompleteStyle}
        options={this.state.worldloc}
        getOptionLabel={(option) => (option && option.name ? option.name : '')}
        disableClearable
        // PaperComponent={({children}) => (
        //   <Paper
        //     style={{
        //       borderRadius: '32px',
        //       marginTop: '12px',
        //       padding: '8px',
        //       width: '170%',
        //       textAlign: 'right !important',
        //     }}
        //     elevation={1}>
        //     {children}
        //   </Paper>
        // )}
        componentsProps={{
          paper: {
            style: paperStyle,
            elevation: isMobile ? 0 : 1,
          },
        }}
        renderOption={(props, option, {selected}) => (
          <Box
            // component="li"
            {...props}
            sx={{
              '&:hover': {
                backgroundColor: '#ebebeb !important',
                borderRadius: '32px !important',
              },
              '&[aria-selected="true"]': {
                backgroundColor: '#ebebeb !important',
                borderRadius: '32px !important',
              },
              display: 'flex',
              justifyContent: 'flex-start !important',
              alignItems: 'center',
              textDecoration: selected ? 'underline' : 'none',
              '& > span': {
                // Properties removed to allow text wrapping
              },
            }}>
            {/* <span>{option && option.name ? option.name : ''}</span> */}
            <span>{option.name || 'Unknown location'}</span>
          </Box>
        )}
        renderInput={(params) => (
          <div style={{position: 'relative'}}>
            <TextField
              {...params}
              label="Location"
              variant="outlined"
              placeholder="Choose location" // Placeholder added here
              inputRef={this.worldLocationInputRef}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style: {
                  paddingRight: '20px',
                  paddingLeft: '20px',
                  paddingTop: '23px',
                },
                endAdornment: null,
                inputProps: {
                  ...params.inputProps,
                  style: {fontSize: '0.9rem'}, // Increase the font size for the placeholder
                },
              }}
              // onClick={() => {
              //   // Clear the selection when the input is clicked
              //   this.setState({worldlocvalue: ''});
              // }}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: true,
              }}
              sx={{
                '& fieldset': {border: 'none'},
                '& .MuiOutlinedInput-root': {
                  border: 'none',
                  borderRadius: '32px',
                  height: '66px',
                  cursor: 'pointer !important',
                  borderColor: 'transparent',
                  '&:hover': {
                    backgroundColor: this.state.openworldlocation
                      ? 'white'
                      : this.state.opencountry ||
                        this.state.openlocation ||
                        this.state.openradius ||
                        this.state.openactivity
                      ? '#dddddd'
                      : '#ebebeb',
                    cursor: 'pointer !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    cursor: 'pointer !important',
                  },
                },
                '& .MuiInputLabel-outlined': {
                  transform: 'translate(26px, 13px) scale(1)',
                  fontWeight: '600',
                  fontSize: '16.2px',
                  color: '#000000',
                  cursor: 'pointer !important',
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(26px, 13px) scale(0.75)',
                    cursor: 'pointer !important',
                  },
                  pointerEvents: 'none',
                },
              }}
            />
            {this.state.worldInputValue && (
              <Tooltip title="Clear location" placement="top">
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: '76px',
                    top: '32%',
                    fontSize: '12px',
                    transform: 'translateY(-50%)',
                    padding: '0px 8px',
                    '&:hover': {
                      color: '#f40057',
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => {
                    this.setState((prevState) => ({
                      worldLocation: undefined,
                      radius: '',
                      searchbar: false,
                      searchval: '',
                      page: 1,
                      worldlocvalue: '',
                      inputValue: '',
                      worldInputValue: '',
                      payload: (() => {
                        const newPayload = {...prevState.payload};
                        delete newPayload.WorldArea;
                        return newPayload;
                      })(),
                      clearedParams: [...prevState.clearedParams, 'wl'],
                    }));
                  }}>
                  <CancelIcon
                    sx={{
                      fontSize: '12px !important',
                      color: 'inherit',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      />
    );
  }

  BusinessAutocomplete({isMobile}) {
    const paperStyle = isMobile
      ? {
          borderRadius: '25px 25px 0px 0px',
          // marginTop: '-69px',
          height: '90vh',
          // marginLeft: '-3px',
          // border: '3px solid red',
          padding: '8px',
          width: '100vw', // '114%' is not responsive, it's better to use calc with 100% and adjust the margins
          // borderRadius: '32px',
          // marginTop: '12px',
          // padding: '8px',
          // // width: '100vw',
        }
      : {
          borderRadius: '32px',
          marginTop: '12px',
          padding: '8px',
          width: '100%',
        };

    const autocompleteStyle = isMobile
      ? {
          paddingLeft: '0px',
          // border: '3px solid red',
          backgroundColor: this.state.openactivity ? '#ffffff' : 'inherit',
          minWidth: '100%',
          borderRadius: '24px',
          // boxShadow: this.state.opencountry
          //   ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
          //   : 'none',
        }
      : {
          paddingLeft: '0px',
          minWidth: '33%',
          backgroundColor: this.state.openactivity ? '#ffffff' : 'inherit',
          borderRadius: '32px',
          boxShadow: this.state.openactivity
            ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
            : 'none',
        };
    const isActivityInputIncluded = (list) =>
      list.some((item) => item.Label === this.state.activityInput);

    // Determine if activityInput is included in either uksic or BUSINESSLIST
    const activityInputIncluded =
      isActivityInputIncluded(uksic) || isActivityInputIncluded(BUSINESSLIST);

    const activityoptions =
      this.state.inputCountry === 'United Kingdom' && this.state.toggle !== 'People'
        ? [
            ...uksic,
            ...BUSINESSLIST,
            // Add activityInput if it is not included already
            ...(!activityInputIncluded && this.state.activityInput
              ? [{Label: this.state.activityInput}]
              : []),
          ]
        : this.state.businessListType === 'sic'
        ? uksic
        : [
            ...BUSINESSLIST,
            // Add activityInput if it is not included already
            ...(!activityInputIncluded && this.state.activityInput
              ? [{Label: this.state.activityInput}]
              : []),
          ];

    // const paperStyle = {
    //   borderRadius: '32px',
    //   marginTop: '12px',
    //   padding: '8px',
    //   width: '170%',
    // };

    const listBoxStyle = isMobile
      ? {
          maxHeight: '85vh', // Adjust to desired view height for mobile
          // overflow: 'auto', // Add overflow property to enable scrolling
          minWidth: '100%',
          paddingLeft: '3px',
        }
      : {
          maxHeight: '40vh', // Adjust to desired view height for desktop
          overflow: 'auto', // Add overflow property to enable scrolling
        };
    return (
      // <ClickAwayListener
      //   onClickAway={(event) => {
      //     if (
      //       !isMobile &&
      //       this.state.selectingOption &&
      //       this.state.openactivity
      //     ) {
      //       this.handleLabel(event, 'openactivity', false);
      //     }
      //   }}
      // >
      <Autocomplete
        // disablePortal
        id="leadsearch-activity"
        loading={this.state.activityload}
        open={this.state.openactivity}
        // onClick={(e) => {
        //   if (isMobile) {
        //     e.stopPropagation();
        //   }
        // }}
        freeSolo
        onOpen={() =>
          this.setState({
            openlocation: false,
            opencountry: false,
            openworldlocation: false,
            openradius: false,
            openactivity: true,
            selectingOption: true,
            activityInputBackup:
              this.props.activityval && this.props.activityval.Label
                ? this.props.activityval.Label
                : this.state.activityInput,
            // activityInput: '',
          })
        }
        // onClose={() => {
        //   if (this.state.activityInput === '') {
        //     // console.log('ACTIVITY INPUT EMPTY', this.state.activityInputBackup);
        //     this.setState({
        //       activityInput: this.state.activityInputBackup,
        //       activityInputBackup: undefined,
        //     });
        //   }
        //   this.setState({
        //     openactivity: false,
        //     selectingOption: false,
        //   });
        // }}
        onMouseEnter={() => this.setState({hovered: 'activity'})}
        onMouseLeave={() => this.setState({hovered: undefined})}
        onFocus={() =>
          this.setState({
            openactivity: true,
            activityInputBackup:
              this.state.activityval && this.state.activityval.Label
                ? this.state.activityval.Label
                : this.props.activityval && this.props.activityval.Label
                ? this.props.activityval.Label
                : this.state.activityInput,
            // activityInput: '',
          })
        }
        onBlur={(event) => {
          // When input loses focus without a new selection
          if (
            !event.target.value &&
            !this.state.clearedParams.includes('ac') &&
            !this.state.clearedParams.includes('a')
          ) {
            this.setState({
              activityInput: this.state.activityInputBackup, // Restore the backed-up value
              activityInputBackup: undefined,
            });
          }
        }}
        // options={uksic}
        // options={this.state.displayedOptions}
        options={
          this.state.inputCountry === 'United Kingdom' && this.state.toggle !== 'People'
            ? [
                ...uksic,
                ...BUSINESSLIST,
                // Add activityInput if it is not included already
                ...(!activityInputIncluded && this.state.activityInput
                  ? [{Label: this.state.activityInput}]
                  : []),
              ]
            : [
                ...BUSINESSLIST,
                // Add activityInput if it is not included already
                ...(!activityInputIncluded && this.state.activityInput
                  ? [{Label: this.state.activityInput}]
                  : []),
              ]
        }
        // options={this.state.powersearch ? BUSINESSLIST : uksic}
        loadingText="Loading..."
        getOptionLabel={(option) => (option.Label ? option.Label : '')}
        value={
          this.state.activityval
            ? this.state.activityval
            : this.props.activityval
            ? this.props.activityval
            : {}
        }
        inputValue={
          typeof this.state.activityInput === 'string' // Directly check if activityInput is a string
            ? this.state.activityInput
            : this.props.activityval && this.props.activityval.Label
            ? this.props.activityval.Label
            : ''
        }
        onInputChange={(event, newInputValue, reason) => {
          // console.log('ACTIVITY INPUT CHANGE', reason, newInputValue);
          if (reason === 'input') {
            // console.log('ACTIVITY INPUT INPUT', reason, newInputValue);
            let sic;
            if (newInputValue['SIC Code']) {
              sic =
                newInputValue['SIC Code'].length === 5
                  ? newInputValue['SIC Code']
                  : `0${newInputValue['SIC Code']}`;
            }
            // console.log('ACTIVITY INPUT SICC', sic, newInputValue, this.state.activityval);
            this.setState({
              activityInput: newInputValue,
              activity: sic,
              activityval: {Label: newInputValue},
              activityInputBackup: undefined,
              payload: {
                ...this.state.payload,
                'SICCode.SicText_1': `${sic}`,
              },
              displayedOptions: this.filterOptions(newInputValue),
            });
            if (typeof this.props.UpdateState === 'function') {
              this.props.UpdateState({
                activityInput: newInputValue,
                activity: sic,
                activityval: {Label: newInputValue},
                activityInputBackup: undefined,
                payload: {
                  ...this.state.payload,
                  'SICCode.SicText_1': `${sic}`,
                },
                displayedOptions: this.filterOptions(newInputValue),
              });
            }
          }
        }}
        // isOptionEqualToValue={(option, value) => option.Postcode === value.Postcode}
        onChange={(e, value) => {
          // console.log('ON CHANGE ACTIVITY', value);
          if (value !== null) {
            // console.log('ON CHANGE ACTIVITY IF', value, this.state.activityval);
            // if (!this.state.powersearch) {
            let sic;
            if (value['SIC Code']) {
              sic = value['SIC Code'].length === 5 ? value['SIC Code'] : `0${value['SIC Code']}`;
            }
            // const psearch = value['SIC Code'] ? false : true;
            console.log('ON CHANGE ACTIVITY IF', sic, value, this.state.activityval, value['SIC Code']);
            this.setState({
              // countload: true,
              // loading: true,
              // searchbar: false,
              // page: 1,
              // searchval: '',
              // powersearch: psearch,
              openactivity: false,
              openjobtitle: false,
              activityInput: value.Label,
              activity: sic,
              activityval: value,
              activityInputBackup: undefined,
              payload: {
                ...this.state.payload,
                'SICCode.SicText_1': `${sic}`,
              },
            });
            if (typeof this.props.UpdateState === 'function') {
              this.props.UpdateState({
                // countload: true,
                // loading: true,
                // searchbar: false,
                // page: 1,
                // searchval: '',
                // powersearch: psearch,
                openactivity: false,
                openjobtitle: false,
                activityInput: value.Label,
                activity: sic,
                activityval: value,
                activityInputBackup: undefined,
                payload: {
                  ...this.state.payload,
                  'SICCode.SicText_1': `${sic}`,
                },
              });
            }
            // const payload = {
            //   ...this.state.payload,
            //   'SICCode.SicText_1': `${sic}`,
            // };
            // this.setState({payload});
            // this.PathGenerator('a', sic, true);
          } else {
            // if (value !== null) {
            //   let sic;
            //   if (value['SIC Code']) {
            //     sic = value['SIC Code'].length === 5 ? value['SIC Code'] : `0${value['SIC Code']}`;
            //   }
            //   this.setState({
            //     powersearch: Object.keys(value).length === 0,
            //     openactivity: false,
            //     openjobtitle: false,
            //     activityInput: value.Label,
            //     activity: sic,
            //     activityval: value,
            //     activityInputBackup: undefined,
            //     payload: {
            //       ...this.state.payload,
            //       'SICCode.SicText_1': `${sic}`,
            //     },
            //   });
            // } else {
            // console.log('ON CHANGE ACTIVITY ELSE', value, this.state.activityval);
            if (this.state.activityval && this.state.activityval.length !== 0) {
              // Create a copy of the current payload
              const newPayload = {...this.state.payload};
              // Delete 'SICCode.SicText_1' from the new payload
              delete newPayload['SICCode.SicText_1'];

              this.setState({
                // countload: true,
                // loading: true,
                activity: undefined,
                activityval: '',
                activityInputBackup: undefined,
                openactivity: false,
                openjobtitle: false,
                // searchbar: false,
                // searchval: '',
                payload: newPayload,
              });
              if (typeof this.props.UpdateState === 'function') {
                this.props.UpdateState({
                  // countload: true,
                  // loading: true,
                  activity: undefined,
                  activityval: '',
                  activityInputBackup: undefined,
                  openactivity: false,
                  openjobtitle: false,
                  // searchbar: false,
                  // searchval: '',
                  payload: newPayload,
                });
              }
              // this.PathGenerator('a', undefined, true);
            }
          }
        }}
        // ListboxComponent={(listboxProps) => (
        //   <ListboxComponent
        //     {...listboxProps}
        //     listoptions={
        //       this.state.displayedOptions ? this.state.displayedOptions : activityoptions
        //     }
        //     // handleScroll={this.handleScroll}
        //     handleActivityChange={this.handleActivityChange}
        //   />
        // )}
        ListboxComponent={ListboxComponent}
        // ListboxComponent={
        //   !isMobile
        //     ? (listboxProps) => (
        //         <ListboxComponent
        //           {...listboxProps}
        //           listoptions={
        //             this.state.displayedOptions ? this.state.displayedOptions : activityoptions
        //           }
        //           handleActivityChange={this.handleActivityChange}
        //         />
        //       )
        //     : undefined // Use the default ListboxComponent on touch devices
        // }
        ListboxProps={{
          onScroll: this.handleScroll,
          onMouseEnter: () => this.setState({mouseInListBox: true}),
          onMouseLeave: () => this.setState({mouseInListBox: false}),
          style: listBoxStyle,
          sx: {
            '&& li.Mui-focused': {
              bgcolor: !this.state.mouseInListBox ? 'transparent' : 'transparent',
            },
          },
        }}
        style={autocompleteStyle}
        // style={{
        //   width: '100%',
        //   backgroundColor: this.state.openactivity ? '#ffffff' : 'inherit',
        //   borderRadius: '32px',
        //   boxShadow: this.state.openactivity
        //     ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
        //     : 'none',
        // }} // Set width to 66% of the parent container
        // PaperComponent={({children}) => (
        //   <Paper
        //     style={{
        //       borderRadius: '32px',
        //       marginTop: '12px',
        //       padding: '8px',
        //       width: '100%',
        //     }}
        //     elevation={1}>
        //     {children}
        //   </Paper>
        // )}
        componentsProps={{
          paper: {
            style: paperStyle,
            elevation: isMobile ? 0 : 1,
          },
          // listbox: {
          //   // Assuming handleScroll is a method in your component that you want to call on scroll
          //   // onScroll: (event) => this.handleScroll(event),
          //   onMouseEnter: () => this.setState({ mouseInListBox: true }),
          //   onMouseLeave: () => this.setState({ mouseInListBox: false }),
          //   style: {
          //     // Apply your dynamic styling here. Example for maxHeight adjustment based on mobile state:
          //     maxHeight: isMobile ? '85vh' : '40vh',
          //     overflow: 'auto',
          //     // You can add more style rules as needed
          //   },
          // },
        }}
        renderOption={(props, option, {selected}) => (
          <Box
            style={{boxShadow: 'none'}}
            sx={{
              '&:hover': {
                backgroundColor: '#ebebeb !important',
                borderRadius: '32px !important',
              },
              '&[aria-selected="true"]': {
                backgroundColor: '#ebebeb !important', // Apply the same background color as hover
                borderRadius: '32px !important', // Apply the same border radius as hover
              },
              display: 'flex',
              justifyContent: 'flex-start !important',
              alignItems: 'center', // Center the items vertically
              textDecoration: selected ? 'underline' : 'none',
              '& > img': {marginRight: '5px', flexShrink: 0},
            }}
            {...props}>
            <>
              {option.id ? (
                <Tooltip
                  title="Get licensed results from the Companies House database to use filters like business size and incorporation date"
                  interactive
                  PopperProps={{
                    style: {zIndex: 99999},
                  }}
                  placement="bottom"
                  style={{zIndex: '10000'}}>
                  <span
                    style={{
                      marginBottom: '-5px',
                      marginLeft: '-1px',
                    }}>
                    <img
                      src={chlogo}
                      alt="Companies House"
                      loading="lazy"
                      style={{
                        width: '14px',
                        marginRight: '10px',
                        // marginBottom: '-2px',
                      }}
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Get licensed results from Google including the website and Google ratings"
                  interactive
                  PopperProps={{
                    style: {zIndex: 99999},
                  }}
                  placement="bottom">
                  <span
                    style={{
                      marginBottom: '-5px',
                      marginRight: '2px',
                    }}>
                    <GoogleIcon
                      style={{
                        fontSize: '11px',
                        marginRight: '10px',
                        marginBottom: '-1px',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              <span>
                <Tooltip
                  interactive
                  PopperProps={{
                    style: {zIndex: 99999},
                  }}
                  placement="bottom"
                  style={{zIndex: '10000'}}
                  title={option.Label || ''}>
                  <span>
                    {option.Label
                      ? option.Label.substring(0, 40) + (option.Label.length > 40 ? '...' : '')
                      : ''}
                  </span>
                </Tooltip>
              </span>
            </>
          </Box>
        )}
        renderInput={(params) => (
          <div style={{position: 'relative'}}>
            <TextField
              {...params}
              label={isMobile ? '' : 'Business'}
              inputRef={this.activityInputRef}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: true,
              }}
              variant="outlined"
              placeholder={
                this.state.activityval
                  ? this.state.activityval.Label
                  : this.props.activityval && this.state.openactivity
                  ? this.props.activityval.Label
                  : 'Choose activity'
              }
              // ListboxComponent={ListboxComponent}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style: {
                  paddingRight: '20px',
                  paddingLeft: '20px',
                  paddingTop: '23px',
                },
                endAdornment: null,
                inputProps: {
                  ...params.inputProps,
                  style: {fontSize: '0.9rem'}, // Increase the font size for the placeholder
                },
              }}
              sx={{
                '& fieldset': {border: 'none'},
                '& .MuiOutlinedInput-root': {
                  border: 'none',
                  borderRadius: '32px',
                  height: '66px',
                  cursor: 'pointer !important',
                  borderColor: 'transparent',
                  '&:hover': {
                    backgroundColor: this.state.openactivity
                      ? 'white'
                      : this.state.opencountry ||
                        this.state.openradius ||
                        this.state.openworldlocation ||
                        this.state.openactivity
                      ? '#dddddd'
                      : '#ebebeb',
                    cursor: 'pointer !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    cursor: 'pointer !important',
                  },
                },
                '& .MuiInputLabel-outlined': {
                  transform: 'translate(26px, 13px) scale(1)',
                  fontWeight: '600',
                  fontSize: '16.2px',
                  color: '#000000',
                  cursor: 'pointer !important',
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(26px, 13px) scale(0.75)',
                    cursor: 'pointer !important',
                  },
                  pointerEvents: 'none',
                },
              }}
            />
            {this.state.activityInput && (
              <Tooltip title="Clear business" placement="top">
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: '78px',
                    top: '32%',
                    fontSize: '12px',
                    transform: 'translateY(-50%)',
                    padding: '0px 8px',
                    '&:hover': {
                      color: '#f40057',
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => {
                    this.setState((prevState) => ({
                      activity: '',
                      activityval: '',
                      activityInput: '',
                      activityInputBackup: undefined,
                      openactivity: false,
                      openjobtitle: false,
                      // searchbar: false,
                      // searchval: '',
                      payload: (() => {
                        const newPayload = {...prevState.payload};
                        delete newPayload['SICCode.SicText_1'];
                        return newPayload;
                      })(),
                      clearedParams: [...prevState.clearedParams, 'a', 'ac'],
                    }));
                    if (typeof this.props.UpdateState === 'function') {
                      this.props.UpdateState({activityval: ''});
                    }
                  }}>
                  <CancelIcon
                    sx={{
                      fontSize: '12px !important',
                      color: 'inherit',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      />
      // </ClickAwayListener>
    );
  }

  FilterDialog() {
    const isActivityInputIncluded = (list) =>
      list.some((item) => item.Label === this.state.activityInput);

    // Determine if activityInput is included in either uksic or BUSINESSLIST
    const activityInputIncluded =
      isActivityInputIncluded(uksic) || isActivityInputIncluded(BUSINESSLIST);

    const selected = !!(
      this.state.opencountry ||
      this.state.openlocation ||
      this.state.openworldlocation ||
      this.state.openradius ||
      this.state.openactivity
    );
    return (
      <Fragment>
        <MuiDialogTitle
          id="customized-dialog-title"
          sx={{
            display: 'flex', // Use flex layout
            justifyContent: 'center', // Center the items horizontally
            alignItems: 'center', // Center the items vertically
            fontSize: '1rem',
            height: '54px',
            fontWeight: '600',
            textAlign: 'center',
            padding: '16px', // Adjust padding as needed
            backgroundColor: 'rgb(247, 247, 247)',
          }}
          onClose={() => this.setState({dialogueOpen: false, title: '', dialoguetext: ''})}>
          {/* IconButton for closing the dialog */}
          <IconButton
            aria-label="close"
            onClick={() => {
              if (
                this.state.opencountry ||
                this.state.openlocation ||
                this.state.openworldlocation ||
                this.state.openactivity ||
                this.state.openjobtitle
              ) {
                this.setState({
                  opencountry: false,
                  openactivity: false,
                  openjobtitle: false,
                  openlocation: false,
                  openworldlocation: false,
                });
              } else {
                this.setState({
                  dialogueOpen: false,
                  title: '',
                  dialoguetext: '',
                });
              }
            }}
            sx={{
              position: 'absolute', // Position the close icon absolutely
              left: '12px', // Adjust the position as per your design
              top: '12px',
              backgroundColor: 'white',
              width: '32px',
              height: '32px',
              color: 'rgba(0, 0, 0, 0.87)', // Adjust color as needed
              // margin: '2px 0px 0px 6px',
              padding: '0px',
              border: '1px solid', // Add a 1px solid border
              borderColor: 'rgb(176, 176, 176)',
            }}>
            {this.state.opencountry || this.state.openactivity ? (
              <ChevronLeftSharpIcon fontSize="small" />
            ) : (
              <ClearSharpIcon fontSize="small" />
            )}
          </IconButton>

          {/* The title text */}
          <span>
            {this.state.opencountry
              ? 'Choose country'
              : this.state.openactivity
              ? 'Choose activity'
              : 'Find companies'}
          </span>
        </MuiDialogTitle>
        <MuiDialogContent
          style={{
            backgroundColor: 'rgb(247, 247, 247)',
            margin: '0px',
            padding: '12px',
            overflow: 'scroll',
          }}>
          {this.state.opencountry ||
          this.state.openactivity ||
          this.state.openlocation ||
          this.state.openworldlocation ? (
            <Grid
              container
              style={{
                paddingLeft: '0px',
                width: '100vw',
                height: '90vh',
                // padding: '24px 24px 12px 24px',
                marginLeft: '-12px',
                backgroundColor: 'white',
                borderRadius: '24px 24px 0px 0px',
                boxShadow: '0 0 0 1px rgb(0 0 0/4%),0 6px 20px rgb(0 0 0/20%)',
              }}>
              {this.state.opencountry ? (
                <this.CountryAutocomplete isMobile />
              ) : this.state.openlocation || this.state.openworldlocation ? (
                <this.LocationAutocomplete isMobile />
              ) : this.state.openactivity ? (
                <this.BusinessAutocomplete isMobile />
              ) : null}
            </Grid>
          ) : (
            <Grid container style={{marginTop: '16px', height: '68vh'}}>
              {/* <MuiDialogContent dividers style={{ padding: '0px' }}> */}
              <Grid
                item
                xs={12}
                spacing={2}
                onClick={() => this.setState({opencountry: true})}
                style={{
                  paddingLeft: '0px',
                  width: '100%',
                  padding: '24px 24px 12px 24px',
                  marginLeft: '0px',
                  backgroundColor: 'white',
                  borderRadius: '24px',
                  boxShadow: '0 0 0 1px rgb(0 0 0/4%),0 6px 20px rgb(0 0 0/20%)',
                }}>
                <Typography
                  variant="h6"
                  align="left"
                  // color="primary"
                  style={{
                    paddingBottom: '8px',
                    fontSize: '20px',
                    fontWeight: '700',
                  }}>
                  Country
                </Typography>
                {/* <Typography
                  variant="body"
                  align="left"
                  // color="primary"
                  style={{
                    paddingBottom: '24px',
                  }}>
                  Choose a country.
                </Typography> */}
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      // paddingLeft: '16px',
                      width: '100%',
                      paddingBottom: '16px',
                    }}>
                    {/* {!this.state.opencountry ? (
                      <this.CountryAutocomplete isMobile />
                    ) : null} */}
                    {/* <Grid item xs={12}> */}
                    <Box
                      // component="li"
                      style={{boxShadow: 'none'}}
                      sx={{
                        fontSize: '0.9rem',
                        '&:hover': {
                          backgroundColor: '#ebebeb !important',
                          borderRadius: '32px !important',
                        },
                        '&[aria-selected="true"]': {
                          backgroundColor: '#ebebeb !important', // Apply the same background color as hover
                          borderRadius: '32px !important', // Apply the same border radius as hover
                        },
                        // marginTop: '18px',
                        display: 'flex',
                        justifyContent: 'flex-start !important',
                        '& > span': {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis', // This will add an ellipsis if the text is too long
                        },
                        alignItems: 'center',
                        textDecoration: selected ? 'underline' : 'none',
                        '& > img': {
                          marginLeft: '3px',
                          marginRight: '9px',
                          flexShrink: 0,
                        },
                      }}>
                      {/* <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            /> */}
                      {this.state.country.code && (
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${this.state.country.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${this.state.country.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                      )}
                      <span>{this.state.country.label}</span>
                    </Box>
                    {/* </Grid> */}
                    {/* <this.CountryAutocomplete isMobile /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                spacing={2}
                onClick={() => {
                  if (this.state.inputCountry !== 'United Kingdom') {
                    this.setState({openworldlocation: true});
                  } else {
                    this.setState({openlocation: true});
                  }
                }}
                style={{
                  marginTop: '12px',
                  paddingLeft: '0px',
                  width: '100%',
                  padding: '24px 24px 12px 24px',
                  // marginTop: '4px !important',
                  marginLeft: '0px',
                  backgroundColor: 'white',
                  borderRadius: '24px',
                  boxShadow: '0 0 0 1px rgb(0 0 0/4%),0 6px 20px rgb(0 0 0/20%)',
                }}>
                <Typography
                  variant="h6"
                  align="left"
                  // color="primary"
                  style={{
                    paddingBottom: '8px',
                    fontSize: '20px',
                    fontWeight: '700',
                  }}>
                  Location
                </Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      // paddingLeft: '16px',
                      width: '100%',
                      paddingBottom: '16px',
                    }}>
                    <p
                      style={{
                        margin: '0px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        fontSize: '14.4px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: this.state.activityInput ? 'inherit' : '#717171',
                      }}>
                      {this.state.ukInputValue
                        ? this.state.ukInputValue
                        : this.state.worldInputValue
                        ? this.state.worldInputValue
                        : 'Choose location'}
                    </p>
                    {/* <this.CountryAutocomplete /> */}
                    {/* <this.LocationAutocomplete /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                spacing={2}
                onClick={() => this.setState({openactivity: true})}
                style={{
                  marginTop: '12px',
                  paddingLeft: '0px',
                  width: '100%',
                  padding: '24px 24px 12px 24px',
                  // marginTop: '4px !important',
                  marginLeft: '0px',
                  backgroundColor: 'white',
                  borderRadius: '24px',
                  boxShadow: '0 0 0 1px rgb(0 0 0/4%),0 6px 20px rgb(0 0 0/20%)',
                }}>
                <Typography
                  variant="h6"
                  align="left"
                  // color="primary"
                  style={{
                    paddingBottom: '8px',
                    fontSize: '20px',
                    fontWeight: '700',
                  }}>
                  Business
                </Typography>
                {/* <Typography
                  variant="body"
                  align="left"
                  // color="primary"
                  style={{
                    paddingBottom: '24px',
                  }}>
                  Choose a country.
                </Typography> */}
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      // paddingLeft: '16px',
                      width: '100%',
                      paddingBottom: '16px',
                    }}>
                    {/* {!this.state.openactivity ? <this.BusinessAutocomplete isMobile /> : null} */}
                    <p
                      style={{
                        margin: '0px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        fontSize: '14.4px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: this.state.activityInput ? 'inherit' : '#717171',
                      }}>
                      {typeof this.state.activityInput === 'string' // Directly check if activityInput is a string
                        ? this.state.activityInput
                        : this.props.activityval && this.props.activityval.Label
                        ? this.props.activityval.Label
                        : 'Choose business'}
                      {/* {this.state.activityInput
                        ? this.state.activityInput
                        : 'Choose business'} */}
                    </p>
                    {/* <this.BusinessAutocomplete isMobile /> */}
                  </Grid>
                </Grid>
              </Grid>
              {/* </MuiDialogContent> */}
            </Grid>
          )}
        </MuiDialogContent>
        <MuiDialogActions
          style={{
            padding: '12px 24px 12px',
            backgroundColor: 'white',
            zIndex: '5001',
          }}>
          <Button
            // autoFocus
            style={{
              textTransform: 'capitalize',
              width: '50%',
              maxWidth: '124px',
            }}
            sx={{
              // position: 'absolute', // Position the button absolutely within its relative parent
              // right: '0px', // Adjust this value to control the button's position from the right
              // top: '50%', // Center the button vertically relative to its parent
              // transform: 'translateY(-50%)', // Adjust vertical positioning to centre the button
              transition: 'all 0.3s ease',
              borderRadius: '32px',
              // borderRadius: selected ? '32px' : '50%',
              // width: selected ? '111.75px' : '48px',
              height: '48px',
              padding: 0,
              backgroundColor: '#003671',
              fontWeight: '600',
              color: 'white',
              // marginRight: '10px',
              minWidth: '0',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              // display: 'flex',
              // alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                backgroundColor: '#194f8a !important',
              },
            }}
            onClick={(event) => {
              event.preventDefault();
              this.setState({searching: true});
              if (typeof this.props.UpdateState === 'function') {
                this.props.UpdateState({
                  loading: true,
                  countload: true,
                });
              }
              // console.log(
              //   'BUTTON CLICKED',
              //   this.state.payload,
              //   this.state.searching
              // );

              let url = '/leadsearch?';
              // const isUK = this.state.country.code === 'GB';
              const isUK = this.state.country.code === 'GB' && !this.state.powersearch;

              // For UK
              if (isUK) {
                const uklocvalue =
                  this.state.uklocvalue && this.state.uklocvalue.Postcode
                    ? this.state.uklocvalue.Postcode
                    : null;
                const payloadValue =
                  this.state.payload && this.state.payload['SICCode.SicText_1']
                    ? this.state.payload['SICCode.SicText_1']
                    : null;
                const radiusValue =
                  this.state.radius && this.state.radius.m ? this.state.radius.m : null;

                if (uklocvalue) {
                  url += `l=${uklocvalue}&`;
                }
                // if (payloadValue) {
                //   url += `a=${payloadValue}&`;
                // }
                if (this.state.activity) {
                  url += `a=${this.state.activity}&`;
                } else if (
                  (this.state.activityval &&
                    this.state.activityval.Label &&
                    !this.state.activity) ||
                  (!this.state.activity && this.state.activityInput)
                ) {
                  url += `ac=${
                    this.state.activityval.Label
                      ? this.state.activityval.Label
                      : this.state.activityInput
                  }&`;
                }
                if (this.props.search) {
                  // Append 'e' parameter if present
                  if (this.props.search.e) {
                    url += `&e=${encodeURIComponent(this.props.search.e)}`;
                  }

                  // Append 'tu' parameter if present
                  if (this.props.search.tu) {
                    url += `&tu=${encodeURIComponent(this.props.search.tu)}`;
                  }

                  // Append 't' parameter if present
                  if (this.props.search.t) {
                    url += `&t=${encodeURIComponent(this.props.search.t)}`;
                  }
                }
                if (radiusValue) {
                  url += `rad=${radiusValue}&`;
                }
                // else {
                //   url += 'rad=2'; // Append the 'rad' parameter at the end
                // }
              }
              // For non-UK
              else {
                const countryCode = this.state.country.code ? this.state.country.code : null;
                const worldlocvalue =
                  this.state.worldlocvalue && this.state.worldlocvalue.geoname_id
                    ? this.state.worldlocvalue.geoname_id
                    : null;
                const activityInput = this.state.activityInput ? this.state.activityInput : null;
                const worldradius =
                  this.state.worldradius && this.state.worldradius.index
                    ? this.state.worldradius.index
                    : null;

                // console.log(
                //   'SEARCH BUTTON URL GENERATOR',
                //   countryCode,
                //   worldlocvalue,
                //   activityInput
                // );

                if (countryCode) {
                  url += `c=${countryCode}&`;
                }
                if (this.state.payload && this.state.payload.geoname_id) {
                  url += `w=${this.state.payload.geoname_id}&`;
                }
                if (activityInput) {
                  url += `ac=${activityInput}&`;
                }
                if (worldradius) {
                  url += `rad=${worldradius}&`;
                }
              }

              // Remove the trailing '&' if exists
              url = url.endsWith('&') ? url.slice(0, -1) : url;

              this.setState({
                opencountry: false,
                openlocation: false,
                openworldlocation: false,
                openradius: false,
                openactivity: false,
                openjobtitle: false,
                dialogueOpen: false,
              });

              // Navigate to the dynamically constructed URL
              // console.log('NAVIGATE PROPS', url);
              this.props.navigate(url, {
                state: {
                  ...this.state,
                  searching: true,
                  loading: true,
                  opencountry: false,
                  openlocation: false,
                  openworldlocation: false,
                  openradius: false,
                  openactivity: false,
                  openjobtitle: false,
                },
              });
            }}
            color="primary">
            Show leads
          </Button>
        </MuiDialogActions>
      </Fragment>
    );
  }

  handleLabel(event, key, open) {
    // console.log('HANDLE LABEL', event, key, open);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({[key]: open});
  }

  handleActivityChange(e, value) {
    // console.log('ON CHANGE ACTIVITY', value);
    if (value !== null) {
      // if (!this.state.powersearch) {
      let sic;
      if (value['SIC Code']) {
        sic = value['SIC Code'].length === 5 ? value['SIC Code'] : `0${value['SIC Code']}`;
      }
      this.setState({
        // countload: true,
        // loading: true,
        // searchbar: false,
        // page: 1,
        // searchval: '',
        openactivity: false,
        activityInput: value.Label,
        activityInputBackup: undefined,
        activity: sic,
        activityval: value,
        payload: {
          ...this.state.payload,
          'SICCode.SicText_1': `${sic}`,
        },
      });
      // const payload = {
      //   ...this.state.payload,
      //   'SICCode.SicText_1': `${sic}`,
      // };
      // this.setState({payload});
      // this.PathGenerator('a', sic, true);
    } else {
      if (this.state.activityval && this.state.activityval.length !== 0) {
        // Create a copy of the current payload
        const newPayload = {...this.state.payload};
        // Delete 'SICCode.SicText_1' from the new payload
        delete newPayload['SICCode.SicText_1'];

        this.setState({
          // countload: true,
          // loading: true,
          openactivity: false,
          activity: undefined,
          activityval: '',
          // searchbar: false,
          // searchval: '',
          payload: newPayload,
        });
        // this.PathGenerator('a', undefined, true);
      }
    }
  }

  UpdateState(state) {
    this.setState(state);
  }

  // handleScroll = (event) => {
  //   const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
  //   // Trigger loading more options only if near the bottom of the list
  //   if (scrollHeight - scrollTop <= clientHeight + 100) {
  //     // Adjust threshold as necessary
  //     this.loadMoreOptions();
  //   }
  // };

  async FetchWorldLocations(props) {
    // console.log('FETCH WORLD LOCATIONS', props);
    return new Promise((resolve, reject) => {
      HTTP_REQUEST_WORLDLOCATIONS(props)
        .then((response) => response.json())
        .then((response) => {
          const worldlocation = response.response.response;
          // console.log('FETCH WORLD LOCATIONS RESPONSE', worldlocation);
          this.setState(
            {
              worldloc: worldlocation,
              locationloading: false,
              initialLoad: false,
              // openworldlocation: true, // Set openworldlocation to true here, once locations are fetched
            },
            () => {
              // If focusing on the world location input is desired immediately after opening:
              if (typeof this.props.UpdateState === 'function') {
                this.props.UpdateState({
                  worldloc: worldlocation,
                  locationloading: false,
                  initialLoad: false,
                  // openworldlocation: true, // Set openworldlocation to true here, once locations are fetched
                });
              }
              this.setState({openworldlocation: props.open});
              this.worldLocationInputRef.current?.focus();
            }
          );
          resolve(worldlocation[0]);
        })
        .catch((error) => {
          console.log(error);
          reject(error); // Ensure to call reject on promise rejection to handle errors properly
        });
    });
  }

  HandleToggle(type) {
    this.setState({toggle: type});
    // this.props.navigate(`/pricing/?d=${price}`);
  }

  async FetchUKLocations(props, isScrolling = false) {
    return new Promise((resolve, reject) => {
      HTTP_REQUEST_UKLOCATIONS(props)
        .then((response) => response.json())
        .then((response) => {
          const uklocation = response.response.response;
          this.setState(
            (prevState) => ({
              ukloc: isScrolling ? [...prevState.ukloc, ...uklocation] : uklocation,
              locationloading: false,
            }),
            () => resolve(uklocation[0])
          );
        })
        .catch((error) => {
          console.log(error);
          this.setState({locationloading: false});
          reject(error);
        });
    });
  }

  filterOptions(inputValue) {
    const {powersearch, activityInput, businessListType} = this.state;
    // console.log('FILTER OPTIONS', inputValue);
    let filteredOptions = [];

    const baseOptions =
      powersearch || this.state.toggle === 'People' ? BUSINESSLIST : [...uksic, ...BUSINESSLIST];

    if (inputValue) {
      // console.log('FILTER OPTIONS INPUT', inputValue);
      const searchLower = inputValue.toLowerCase();

      // Filter options based on input value
      filteredOptions = baseOptions.filter((option) =>
        option.Label.toLowerCase().includes(searchLower)
      );

      // If the input doesn't exactly match any option, add it as an option
      const inputMatches = filteredOptions.some(
        (option) => option.Label.toLowerCase() === searchLower
      );
      if (!inputMatches && inputValue) {
        filteredOptions.push({Label: inputValue});
      }
    } else {
      // If there's no input value, consider all options
      // console.log('FILTER OPTIONS NO IN', inputValue);
      filteredOptions = baseOptions;
    }

    // Add activityInput to the list if it's unique and not empty
    if (inputValue && !filteredOptions.some((option) => option.Label === inputValue)) {
      // console.log('FILTER OPTIONS PUSH LABEL', inputValue);
      filteredOptions.push({Label: inputValue});
    }

    return filteredOptions;
  }

  render() {
    // Define a function to check if activityInput is in the provided list
    const isActivityInputIncluded = (list) =>
      list.some((item) => item.Label === this.state.activityInput);

    // Determine if activityInput is included in either uksic or BUSINESSLIST
    const activityInputIncluded =
      isActivityInputIncluded(uksic) || isActivityInputIncluded(BUSINESSLIST);

    const selected = !!(
      this.state.opencountry ||
      this.state.openlocation ||
      this.state.openworldlocation ||
      this.state.openradius ||
      this.state.openactivity
    );

    const {loading} = this.props; // Destructuring for easier access
    const {searching} = this.state;

    // Condition to check if we should show CircularProgress
    const shouldShowCircularProgress = loading || searching;

    return (
      <ClickAwayListener
        onClickAway={(event) => {
          // if (this.state.selectingOption) {
          this.setState({
            opencountry: false,
            openlocation: false,
            openworldlocation: false,
            openradius: false,
            openjobtitle: false,
            openactivity: false,
            selectingOption: false,
          });
          // }
        }}>
        <div style={{width: '100%'}}>
          <Dialog
            onClose={() =>
              this.setState({
                dialogueOpen: false,
                title: '',
                dialoguetext: '',
              })
            }
            aria-labelledby="customized-dialog-title"
            PaperProps={{
              style: {
                boxShadow: 'none',
                // width: '100vw',
                minHeight: '100%',
                minWidth: '100%',
                margin: '0px',
                // minWidth: '400px',
                backgroundColor: '#fafafa',
                borderRadius: '0px',
                zIndex: (theme) => theme.zIndex.modal + 5100,
                // zIndex: '5100 !important',
              },
            }}
            open={this.state.dialogueOpen}>
            <this.FilterDialog />
          </Dialog>

          <Hidden mdUp>
            <AppBar
              position="relative"
              style={{
                borderRadius: '32px',
                // maxWidth: '850px',
                // minWidth: '84vw',
                height: '68px',
                // boxShadow:
                //   '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                // boxShadow: selected ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)' : 'none',
              }}>
              <Toolbar
                component={Paper}
                elevation={1}
                sx={{
                  justifyContent: 'space-between',
                  // padding: '2px 10px !important', // Updated padding
                  padding: '0px !important', // Updated padding
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgb(221, 221, 221)',
                  borderRadius: '32px',
                  height: '68px',
                  boxShadow: !selected
                    ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
                    : 'none',
                  backgroundColor:
                    this.state.opencountry ||
                    this.state.openlocation ||
                    this.state.openradius ||
                    this.state.openworldlocation ||
                    this.state.openactivity
                      ? '#ebebeb'
                      : '#ffffff',
                }}>
                <Grid
                  item
                  xs={3}
                  style={{paddingLeft: '18px', width: '68px'}}
                  onClick={() => this.setState({dialogueOpen: true})}>
                  <SearchSharpIcon style={{fontSize: '30px', marginTop: '6px'}} />
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{width: '100%'}}
                  onClick={() => this.setState({dialogueOpen: true})}>
                  <Grid item xs={12}>
                    <Box
                      style={{boxShadow: 'none', maxWidth: '95%'}}
                      sx={{
                        fontSize: '0.9rem',
                        '&:hover': {
                          backgroundColor: '#ebebeb !important',
                          borderRadius: '32px !important',
                        },
                        '&[aria-selected="true"]': {
                          backgroundColor: '#ebebeb !important',
                          borderRadius: '32px !important',
                        },
                        display: 'flex',
                        justifyContent: 'flex-start !important',
                        alignItems: 'center',
                        textDecoration: selected ? 'underline' : 'none',
                        '& > img': {
                          marginLeft: '3px',
                          marginRight: '9px',
                          flexShrink: 0,
                        },
                      }}>
                      {this.state.country.code && (
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${this.state.country.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${this.state.country.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                      )}
                      <span>{this.state.country.label}</span>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{paddingLeft: '2px', display: 'flex', alignItems: 'center'}}>
                      <p
                        style={{
                          margin: '0px',
                          maxWidth: '30vw',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          color:
                            this.state.ukInputValue || this.state.worldInputValue
                              ? 'inherit'
                              : '#717171',
                        }}>
                        {this.state.ukInputValue
                          ? this.state.ukInputValue
                          : this.state.worldInputValue
                          ? this.state.worldInputValue
                          : 'Choose location'}
                      </p>
                      <p
                        style={{
                          padding: '0px 6px',
                          margin: '0px',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}>
                        ·
                      </p>
                      <p
                        style={{
                          margin: '0px',
                          maxWidth: '30vw',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          color: this.state.activityInput ? 'inherit' : '#717171',
                        }}>
                        {typeof this.state.activityInput === 'string'
                          ? this.state.activityInput
                          : this.props.activityval && this.props.activityval.Label
                          ? this.props.activityval.Label
                          : 'Choose business'}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Hidden>
          <Hidden mdDown>
            <AppBar
              position="relative"
              style={{
                borderRadius: '32px',
                maxWidth: '850px',
                width: '100%',
                margin: 'auto',
                // minWidth: '86vw',
                // boxShadow:
                //   '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                // boxShadow: selected ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)' : 'none',
              }}>
              {this.state.toggle === 'Companies' ? (
                <Toolbar
                  component={Paper}
                  elevation={1}
                  sx={{
                    justifyContent: 'space-between',
                    // padding: '2px 10px !important', // Updated padding
                    padding: '0px !important', // Updated padding
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid rgb(221, 221, 221)',
                    borderRadius: '32px',
                    boxShadow: !selected
                      ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
                      : 'none',
                    backgroundColor:
                      this.state.opencountry ||
                      this.state.openlocation ||
                      this.state.openradius ||
                      this.state.openworldlocation ||
                      this.state.openactivity
                        ? '#ebebeb'
                        : '#ffffff',
                  }}>
                  {/* <Hidden mdDown> */}
                  <Grid container>
                    <ClickAwayListener
                      onClickAway={(event) => {
                        if (this.state.selectingOption && this.state.opencountry) {
                          this.handleLabel(event, 'opencountry', false);
                        }
                      }}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{padding: '0px'}}
                        sx={{
                          // position: 'relative',
                          // '&:not(:last-of-type)::after': {
                          //   content: '""',
                          //   position: 'absolute',
                          //   right: 0,
                          //   top: '50%',
                          //   transform: 'translateY(-50%)',
                          //   height: '30px', // Match your Box height
                          //   width: '1px', // Divider thickness
                          //   // Conditionally set the background color based on the component's state
                          //   backgroundColor: (theme) =>
                          //   this.state.opencountry ||
                          //   this.state.openlocation ||
                          //   this.state.openworldlocation ||
                          //     ['country', 'location', 'worldlocation'].includes(this.state.hovered)
                          //       ? 'transparent'
                          //       : ['country', 'location', 'worldlocation'].includes(this.state.hovered)
                          //       ? 'white'
                          //       : 'divider',
                          // },
                          position: 'relative',
                          // Right Divider for medium screens and up
                          '&::after': {
                            content: {sm: '""'}, // This ensures that content is only created for md and up
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            height: '30px', // Adjust as needed
                            width: {sm: '1px'}, // Width is only applied for md and up
                            backgroundColor: (theme) =>
                              this.state.opencountry ||
                              this.state.openlocation ||
                              this.state.openworldlocation ||
                              ['country', 'location', 'worldlocation'].includes(this.state.hovered)
                                ? 'transparent'
                                : ['country', 'location', 'worldlocation'].includes(
                                    this.state.hovered
                                  )
                                ? 'white'
                                : 'divider',
                            display: {xs: 'none', sm: 'block'},
                          },
                          // Bottom Divider for small screens
                          '&::before': {
                            content: {xs: '""', sm: 'none'}, // Content is created for xs but none for md and up
                            position: 'absolute',
                            bottom: 0,
                            left: '24px',
                            width: 'calc(100% - 48px)', // Full width
                            height: {xs: '1px'}, // Height is only applied for xs
                            backgroundColor: (theme) =>
                              this.state.opencountry ||
                              this.state.openlocation ||
                              this.state.openworldlocation ||
                              ['country', 'location', 'worldlocation'].includes(this.state.hovered)
                                ? 'transparent'
                                : ['country', 'location', 'worldlocation'].includes(
                                    this.state.hovered
                                  )
                                ? 'white'
                                : 'divider',
                            // Show the bottom divider only on small screens
                            display: {xs: 'block', sm: 'none'},
                          },
                        }}>
                        <this.CountryAutocomplete isMobile={false} />
                      </Grid>
                    </ClickAwayListener>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{padding: '0px'}}
                      sx={{
                        position: 'relative',
                        padding: '0px',
                        // Right Divider for medium screens and up
                        '&::after': {
                          content: {sm: '""'}, // Create content for md and up
                          position: 'absolute',
                          right: 0,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          height: '30px',
                          width: {sm: '1px'}, // Only applies to md and up
                          backgroundColor: (theme) =>
                            this.state.openradius ||
                            this.state.openactivity ||
                            ['radius', 'activity'].includes(this.state.hovered)
                              ? 'transparent'
                              : theme.palette.divider,
                          display: {xs: 'none', sm: 'block'}, // Hide on xs, show on md
                        },
                        // Bottom Divider for small screens
                        '&::before': {
                          content: {xs: '""', sm: 'none'}, // Create content for xs, none for md and up
                          position: 'absolute',
                          bottom: 0,
                          left: '24px',
                          width: 'calc(100% - 48px)', // Full width
                          height: {xs: '1px'}, // Only applies to xs
                          backgroundColor: (theme) =>
                            this.state.openradius ||
                            this.state.openactivity ||
                            ['radius', 'activity', 'location', 'worldlocation'].includes(
                              this.state.hovered
                            )
                              ? 'transparent'
                              : theme.palette.divider,
                          display: {xs: 'block', sm: 'none'}, // Show on xs, hide on md
                        },
                        '&:last-of-type::after': {
                          content: 'none', // Remove content for the last item to avoid having a right divider
                        },
                        // position: 'relative',
                        // '&:not(:last-of-type)::after': {
                        //   content: '""',
                        //   position: 'absolute',
                        //   right: 0,
                        //   top: '50%',
                        //   transform: 'translateY(-50%)',
                        //   height: '30px', // Match your Box height
                        //   width: '1px', // Divider thickness

                        //   // Conditionally set the background color based on the component's state
                        //   backgroundColor: (theme) =>
                        //     this.state.openradius ||
                        //     this.state.openactivity ||
                        //     ['radius', 'activity'].includes(this.state.hovered)
                        //       ? 'transparent'
                        //       : ['radius', 'activity'].includes(this.state.hovered)
                        //       ? theme.palette.common.white
                        //       : theme.palette.divider,
                        // },
                      }}>
                      {this.state.inputCountry === 'United Kingdom' && !this.state.powersearch ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0, // Adjust the gap as needed
                            paddingLeft: '0px',
                            minWidth: '34%',
                          }}>
                          <ClickAwayListener
                            onClickAway={(event) => {
                              if (this.state.selectingOption && this.state.openlocation) {
                                this.handleLabel(event, 'openlocation', false);
                              }
                            }}>
                            <this.LocationAutocomplete isMobile={false} />
                          </ClickAwayListener>
                          {/* <ClickAwayListener
                            onClickAway={(event) => {
                              if (this.state.selectingOption && this.state.openradius) {
                                this.handleLabel(event, 'openradius', false);
                              }
                            }}> */}
                          <Autocomplete
                            // disablePortal
                            disableClearable
                            id="leadsearch-location-radius"
                            open={this.state.openradius}
                            inputValue={this.state.radiusInputValue}
                            value={this.state.radius}
                            onOpen={() =>
                              this.setState({
                                openradius: true,
                                openlocation: false,
                                openworldlocation: false,
                                opencountry: false,
                                openactivity: false,
                                openjobtitle: false,
                                selectingOption: true,
                              })
                            }
                            filterOptions={(options, state) => options}
                            onMouseEnter={() => this.setState({hovered: 'radius'})}
                            onMouseLeave={() => this.setState({hovered: undefined})}
                            onClose={() =>
                              this.setState({
                                openradius: false,
                                selectingOption: false,
                              })
                            }
                            // onChange={this.HandleRadiusChange}
                            ListboxProps={{
                              onMouseEnter: () => this.setState({mouseInListBox: true}),
                              onMouseLeave: () => this.setState({mouseInListBox: false}),
                              sx: {
                                '&& li.Mui-focused': {
                                  bgcolor: !this.state.mouseInListBox
                                    ? 'transparent'
                                    : 'transparent',
                                },
                              },
                            }}
                            onChange={(e, value) => {
                              // console.log('ON CHANGE RADIUS', value);
                              if (value !== null) {
                                this.setState(
                                  {
                                    openradius: false,
                                    radiusInputValue: value.miles,
                                    radius: value,
                                    payload: {
                                      ...this.state.payload,
                                      Radius: value,
                                    },
                                    openactivity: true,
                                  },
                                  () => {
                                    if (this.activityInputRef.current) {
                                      this.activityInputRef.current.focus();
                                    }
                                  }
                                );
                              } else {
                                if (this.state.uklocvalue && this.state.uklocvalue.length !== 0) {
                                  this.setState(
                                    {
                                      // openlocation: false,
                                      // openactivity: true,
                                      radius: '',
                                      page: 1,
                                      payload: {
                                        ...this.state.payload,
                                      },
                                    },
                                    () => {
                                      this.setState({openactivity: true}, () => {
                                        if (this.activityInputRef.current) {
                                          this.activityInputRef.current.focus();
                                        }
                                      });
                                    }
                                  );
                                }
                              }
                            }}
                            options={ukradiusoptions}
                            getOptionLabel={(option) => (option.miles ? `${option.miles}` : '')}
                            style={{
                              width: '41%',
                              backgroundColor: this.state.openradius ? '#ffffff' : 'inherit',
                              borderRadius: '32px',
                              boxShadow: this.state.openradius
                                ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
                                : 'none',
                            }} // Set width to 33% of the parent container
                            PaperComponent={({children}) => (
                              <Paper
                                style={{
                                  borderRadius: '32px',
                                  marginTop: '12px',
                                  padding: '8px',
                                  width: '245%',
                                  left: '-145%',
                                  position: 'absolute',
                                }}
                                elevation={1}>
                                {children}
                              </Paper>
                            )}
                            renderOption={(props, option, {selected}) => (
                              <Box
                                component="li"
                                style={{boxShadow: 'none'}}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: '#ebebeb !important',
                                    borderRadius: '32px !important',
                                  },
                                  '&[aria-selected="true"]': {
                                    backgroundColor: '#ebebeb !important', // Apply the same background color as hover
                                    borderRadius: '32px !important', // Apply the same border radius as hover
                                  },
                                  // marginTop: '18px',
                                  display: 'flex',
                                  justifyContent: 'flex-end !important',
                                  paddingRight: '4px',
                                  // '& > span': {
                                  //   whiteSpace: 'nowrap',
                                  //   overflow: 'hidden',
                                  //   textOverflow: 'ellipsis', // This will add an ellipsis if the text is too long
                                  // },
                                  alignItems: 'center',
                                  textDecoration: selected ? 'underline' : 'none',
                                  '& > img': {
                                    marginRight: '5px',
                                    flexShrink: 0,
                                  },
                                }}
                                {...props}>
                                <span>{option.miles ? `${option.miles}` : ''}</span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <div style={{position: 'relative'}}>
                                <TextField
                                  {...params}
                                  label="Radius"
                                  variant="outlined"
                                  placeholder="+2 miles"
                                  InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: {
                                      paddingRight: '20px',
                                      paddingLeft: '20px',
                                      paddingTop: '23px',
                                    },
                                    // Remove dropdown arrow
                                    endAdornment: null,
                                    inputProps: {
                                      ...params.inputProps,
                                      style: {fontSize: '0.9rem'}, // Increase the font size for the placeholder
                                    },
                                  }}
                                  sx={{
                                    '& fieldset': {border: 'none'},
                                    '& .MuiOutlinedInput-root': {
                                      border: 'none',
                                      borderRadius: '32px',
                                      height: '66px',
                                      cursor: 'pointer !important',
                                      borderColor: 'transparent',
                                      '&:hover': {
                                        backgroundColor: this.state.openradius
                                          ? 'white'
                                          : this.state.opencountry ||
                                            this.state.openlocation ||
                                            this.state.openworldlocation ||
                                            this.state.openactivity
                                          ? '#dddddd'
                                          : '#ebebeb',
                                        cursor: 'pointer !important',
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                        cursor: 'pointer !important',
                                      },
                                    },
                                    '& .MuiInputLabel-outlined': {
                                      transform: 'translate(24px, 13px) scale(1)',
                                      fontWeight: '600',
                                      fontSize: '16.2px',
                                      paddingRight: '20px',
                                      color: '#000000',
                                      cursor: 'pointer !important',
                                      '&.MuiInputLabel-shrink': {
                                        transform: 'translate(24px, 13px) scale(0.75)',
                                        cursor: 'pointer !important',
                                      },
                                      pointerEvents: 'none',
                                    },
                                  }}
                                />
                                {this.state.radiusInputValue && (
                                  <Tooltip title="Clear radius" placement="top">
                                    <IconButton
                                      sx={{
                                        position: 'absolute',
                                        left: '64px',
                                        top: '32%',
                                        fontSize: '12px',
                                        transform: 'translateY(-50%)',
                                        padding: '0px 8px',
                                        '&:hover': {
                                          color: '#f40057',
                                          backgroundColor: 'transparent',
                                        },
                                      }}
                                      onClick={() => {
                                        this.setState((prevState) => ({
                                          radius: '',
                                          radiusInputValue: '',
                                          searchbar: false,
                                          searchval: '',
                                          page: 1,
                                          inputValue: '',
                                          payload: (() => {
                                            const newPayload = {...prevState.payload};
                                            delete newPayload.Radius;
                                            return newPayload;
                                          })(),
                                          clearedParams: [...prevState.clearedParams, 'rad'],
                                        }));
                                      }}>
                                      <CancelIcon
                                        sx={{
                                          fontSize: '12px !important',
                                          color: 'inherit',
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          />
                          {/* </ClickAwayListener> */}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0, // Adjust the gap as needed
                            paddingLeft: '0px',
                            minWidth: '34%',
                          }}>
                          <ClickAwayListener
                            onClickAway={(event) => {
                              if (!this.state.selectingOption && this.state.openworldlocation) {
                                this.handleLabel(event, 'openworldlocation', false);
                              }
                            }}>
                            <this.LocationAutocomplete isMobile={false} />
                          </ClickAwayListener>
                          <ClickAwayListener
                            onClickAway={(event) => {
                              if (this.state.selectingOption && this.state.openradius) {
                                this.handleLabel(event, 'openradius', false);
                              }
                            }}>
                            <Autocomplete
                              // disablePortal
                              disableClearable
                              id="leadsearch-location-radius"
                              open={this.state.openradius}
                              onOpen={() =>
                                this.setState({
                                  openradius: true,
                                  openlocation: false,
                                  openworldlocation: false,
                                  opencountry: false,
                                  openactivity: false,
                                  openjobtitle: false,
                                  selectingOption: true,
                                })
                              }
                              value={this.state.radius}
                              inputValue={this.state.worldRadiusInputValue}
                              filterOptions={(options, state) => options}
                              onMouseEnter={() => this.setState({hovered: 'radius'})}
                              onMouseLeave={() => this.setState({hovered: undefined})}
                              onClose={() =>
                                this.setState({
                                  openradius: false,
                                  selectingOption: false,
                                })
                              }
                              onChange={(e, value) => {
                                // console.log('ON CHANGE RADIUS', value);
                                if (value !== null) {
                                  this.setState(
                                    {
                                      openradius: false,
                                      worldRadiusInputValue: value.miles,
                                      worldradius: value,
                                      // uklocvalue: value,
                                      payload: {
                                        ...this.state.payload,
                                        Area: `${value.km}`,
                                      },
                                      openactivity: true,
                                    },
                                    () => {
                                      if (this.activityInputRef.current) {
                                        this.activityInputRef.current.focus();
                                      }
                                    }
                                  );
                                } else {
                                  if (
                                    this.state.worldInputValue &&
                                    this.state.worldInputValue.length !== 0
                                  ) {
                                    this.setState(
                                      {
                                        worldRadiusInputValue: '',
                                        worldradius: {},
                                        radius: '',
                                        page: 1,
                                        payload: {
                                          ...this.state.payload,
                                        },
                                      },
                                      () => {
                                        this.setState({openactivity: true}, () => {
                                          if (this.activityInputRef.current) {
                                            this.activityInputRef.current.focus();
                                          }
                                        });
                                      }
                                    );
                                  }
                                }
                              }}
                              // onChange={this.HandleRadiusChange}
                              ListboxProps={{
                                onMouseEnter: () => this.setState({mouseInListBox: true}),
                                onMouseLeave: () => this.setState({mouseInListBox: false}),
                                sx: {
                                  '&& li.Mui-focused': {
                                    bgcolor: !this.state.mouseInListBox
                                      ? 'transparent'
                                      : 'transparent',
                                  },
                                },
                              }}
                              options={radiusoptions}
                              getOptionLabel={(option) => (option.miles ? `${option.miles}` : '')}
                              style={{
                                width: '41% !important',
                                backgroundColor: this.state.openradius ? '#ffffff' : 'inherit',
                                borderRadius: '32px',
                                boxShadow: this.state.openradius
                                  ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
                                  : 'none',
                              }} // Set width to 33% of the parent container
                              PaperComponent={({children}) => (
                                <Paper
                                  style={{
                                    borderRadius: '32px',
                                    marginTop: '12px',
                                    padding: '8px',
                                    width: '245%',
                                    left: '-145%',
                                    position: 'absolute',
                                  }}
                                  elevation={1}>
                                  {children}
                                </Paper>
                              )}
                              renderOption={(props, option, {selected}) => (
                                <Box
                                  // component="li"
                                  style={{boxShadow: 'none'}}
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: '#ebebeb !important',
                                      borderRadius: '32px !important',
                                    },
                                    '&[aria-selected="true"]': {
                                      backgroundColor: '#ebebeb !important', // Apply the same background color as hover
                                      borderRadius: '32px !important', // Apply the same border radius as hover
                                    },
                                    // marginTop: '18px',
                                    display: 'flex',
                                    justifyContent: 'flex-end !important',
                                    paddingRight: '4px',
                                    // '& > span': {
                                    //   whiteSpace: 'nowrap',
                                    //   overflow: 'hidden',
                                    //   textOverflow: 'ellipsis', // This will add an ellipsis if the text is too long
                                    // },
                                    alignItems: 'center',
                                    textDecoration: selected ? 'underline' : 'none',
                                    '& > img': {
                                      marginRight: '5px',
                                      flexShrink: 0,
                                    },
                                  }}
                                  {...props}>
                                  <span>{option.miles ? `${option.miles}` : ''}</span>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <div style={{position: 'relative'}}>
                                  <TextField
                                    {...params}
                                    label="Radius"
                                    variant="outlined"
                                    placeholder="+2 miles"
                                    InputLabelProps={{
                                      ...params.InputLabelProps,
                                      shrink: true,
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: {
                                        paddingRight: '20px',
                                        paddingLeft: '20px',
                                        paddingTop: '23px',
                                      },
                                      // Remove dropdown arrow
                                      endAdornment: null,
                                      inputProps: {
                                        ...params.inputProps,
                                        style: {fontSize: '0.9rem'}, // Increase the font size for the placeholder
                                      },
                                    }}
                                    sx={{
                                      '& fieldset': {border: 'none'},
                                      '& .MuiOutlinedInput-root': {
                                        border: 'none',
                                        borderRadius: '32px',
                                        height: '66px',
                                        cursor: 'pointer !important',
                                        borderColor: 'transparent',
                                        '&:hover': {
                                          backgroundColor: this.state.openradius
                                            ? 'white'
                                            : this.state.opencountry ||
                                              this.state.openlocation ||
                                              this.state.openworldlocation ||
                                              this.state.openactivity
                                            ? '#dddddd'
                                            : '#ebebeb',
                                          cursor: 'pointer !important',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                          borderColor: 'transparent',
                                          cursor: 'pointer !important',
                                        },
                                      },
                                      '& .MuiInputLabel-outlined': {
                                        transform: 'translate(24px, 13px) scale(1)',
                                        fontWeight: '600',
                                        fontSize: '16.2px',
                                        paddingRight: '20px',
                                        color: '#000000',
                                        cursor: 'pointer !important',
                                        '&.MuiInputLabel-shrink': {
                                          transform: 'translate(24px, 13px) scale(0.75)',
                                          cursor: 'pointer !important',
                                        },
                                        pointerEvents: 'none',
                                      },
                                    }}
                                  />
                                  {this.state.worldRadiusInputValue && (
                                    <Tooltip title="Clear radius" placement="top">
                                      <IconButton
                                        sx={{
                                          position: 'absolute',
                                          left: '64px',
                                          top: '32%',
                                          fontSize: '12px',
                                          transform: 'translateY(-50%)',
                                          padding: '0px 8px',
                                          '&:hover': {
                                            color: '#f40057',
                                            backgroundColor: 'transparent',
                                          },
                                        }}
                                        onClick={() => {
                                          this.setState((prevState) => ({
                                            worldRadiusInputValue: '',
                                            worldradius: {},
                                            radius: '',
                                            page: 1,
                                            searchbar: false,
                                            searchval: '',
                                            inputValue: '',
                                            payload: (() => {
                                              const newPayload = {...prevState.payload};
                                              delete newPayload.Area;
                                              return newPayload;
                                            })(),
                                            clearedParams: [...prevState.clearedParams, 'rad'],
                                          }));
                                        }}>
                                        <CancelIcon
                                          sx={{
                                            fontSize: '12px !important',
                                            color: 'inherit',
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              )}
                            />
                          </ClickAwayListener>
                        </Box>
                      )}
                    </Grid>

                    {/* Divider */}
                    {/* <Box
                    sx={{
                      width: '1px',
                      height: '30px',
                      backgroundColor:
                        this.state.openradius ||
                        this.state.openactivity ||
                        // !selected ||
                        ['radius', 'activity'].includes(this.state.hovered)
                          ? 'transparent'
                          : ['radius', 'activity'].includes(this.state.hovered)
                          ? 'white'
                          : 'divider',
                      mx: 0,
                    }}
                    /> */}
                    {/* Check-out Input */}
                    <Grid item xs={12} sm={4} style={{padding: '0px', position: 'relative'}}>
                      {/* <div style={{position: 'relative', width: '33%'}}> */}
                      <ClickAwayListener
                        onClickAway={(event) => {
                          if (this.state.selectingOption && this.state.openactivity) {
                            this.handleLabel(event, 'openactivity', false);
                          }
                        }}>
                        <this.BusinessAutocomplete isMobile={false} />
                      </ClickAwayListener>
                      {/* Search Button */}
                      <Hidden mdDown>
                        <Tooltip
                          title={`Search${
                            this.props.powersearch ||
                            this.props.search.ac ||
                            (this.state.activityval && !this.state.activityval._id)
                              ? ' Google Maps for 1 credit'
                              : ' Companies House for free'
                          }`}
                          placement="bottom">
                          <Button
                            type="submit"
                            aria-label="search"
                            disabled={this.state.searching}
                            // onClick={(event) => {
                            //   event.preventDefault();
                            //   this.setState({searching: true});
                            //   if (typeof this.props.UpdateState === 'function') {
                            //     this.props.UpdateState({
                            //       loading: true,
                            //       countload: true,
                            //     });
                            //   }
                            //   // console.log(
                            //   //   'DESKTOP BUTTON CLICKED',
                            //   //   this.state.payload,
                            //   //   this.state.searching,
                            //   //   this.state.country,
                            //   //   this.props,
                            //   //   this.state,
                            //   // );

                            //   let url = '/leadsearch/?';
                            //   const isUK = this.state.inputCountry === 'United Kingdom';

                            //   // For UK
                            //   if (isUK) {
                            //     const uklocvalue =
                            //       this.state.uklocvalue && this.state.uklocvalue.Postcode
                            //         ? this.state.uklocvalue.Postcode
                            //         : null;
                            //     const payloadValue =
                            //       this.state.payload && this.state.payload['SICCode.SicText_1']
                            //         ? this.state.payload['SICCode.SicText_1']
                            //         : null;
                            //     const radiusValue =
                            //       this.state.radius && this.state.radius.m ? this.state.radius.m : null;

                            //     if (uklocvalue) {
                            //       url += `l=${uklocvalue}&`;
                            //     }
                            //     // if (payloadValue) {
                            //     //   url += `a=${payloadValue}&`;
                            //     // }
                            //     if (this.state.activity) {
                            //       url += `a=${this.state.activity}&`;
                            //     } else if (
                            //       (this.state.activityval &&
                            //         this.state.activityval.Label &&
                            //         !this.state.activity) ||
                            //       (!this.state.activity && this.state.activityInput)
                            //     ) {
                            //       url += `ac=${
                            //         this.state.activityval.Label
                            //           ? this.state.activityval.Label
                            //           : this.state.activityInput
                            //       }&`;
                            //     }
                            //     if (this.props.search) {
                            //       // Append 'e' parameter if present
                            //       if (this.props.search.e) {
                            //         url += `&e=${encodeURIComponent(this.props.search.e)}`;
                            //       }

                            //       // Append 'tu' parameter if present
                            //       if (this.props.search.tu) {
                            //         url += `&tu=${encodeURIComponent(this.props.search.tu)}`;
                            //       }

                            //       // Append 't' parameter if present
                            //       if (this.props.search.t) {
                            //         url += `&t=${encodeURIComponent(this.props.search.t)}`;
                            //       }
                            //     }
                            //     if (radiusValue) {
                            //       url += `rad=${radiusValue}&`;
                            //     }
                            //     // else {
                            //     //   url += 'rad=2'; // Append the 'rad' parameter at the end
                            //     // }
                            //   }
                            //   // For non-UK
                            //   else {
                            //     const countryCode = this.state.country.code
                            //       ? this.state.country.code
                            //       : null;
                            //     const worldlocvalue =
                            //       this.state.worldlocvalue && this.state.worldlocvalue.geoname_id
                            //         ? this.state.worldlocvalue.geoname_id
                            //         : null;
                            //     const activityInput = this.state.activityInput
                            //       ? this.state.activityInput
                            //       : null;
                            //     const worldradius =
                            //       this.state.worldradius && this.state.worldradius.index
                            //         ? this.state.worldradius.index
                            //         : null;

                            //     // console.log(
                            //     //   'SEARCH BUTTON URL GENERATOR',
                            //     //   countryCode,
                            //     //   worldlocvalue,
                            //     //   activityInput
                            //     // );

                            //     if (countryCode) {
                            //       url += `c=${countryCode}&`;
                            //     }
                            //     if (this.state.payload && this.state.payload.geoname_id) {
                            //       url += `w=${this.state.payload.geoname_id}&`;
                            //     }
                            //     if (activityInput) {
                            //       url += `ac=${activityInput}&`;
                            //     }
                            //     if (worldradius) {
                            //       url += `rad=${worldradius}&`;
                            //     }
                            //   }

                            //   // Remove the trailing '&' if exists
                            //   url = url.endsWith('&') ? url.slice(0, -1) : url;

                            //   this.setState({
                            //     opencountry: false,
                            //     openlocation: false,
                            //     openworldlocation: false,
                            //     openradius: false,
                            //     openactivity: false,
                            //   });

                            //   // Navigate to the dynamically constructed URL
                            //   // console.log('NAVIGATE PROPS', url);
                            //   this.props.navigate(url, {
                            //     state: {
                            //       ...this.state,
                            //       searching: true,
                            //       loading: true,
                            //       opencountry: false,
                            //       openlocation: false,
                            //       openworldlocation: false,
                            //       openradius: false,
                            //       openactivity: false,
                            //     },
                            //   });
                            // }}
                            onClick={(event) => {
                              event.preventDefault();
                              this.setState({searching: true});

                              if (typeof this.props.UpdateState === 'function') {
                                this.props.UpdateState({
                                  loading: true,
                                  countload: true,
                                });
                              }

                              const isUK = this.state.inputCountry === 'United Kingdom';
                              // if (
                              //   this.state.activityval &&
                              //   Object.keys(this.state.activityval).length === 1
                              // ) {
                              //   isUK = false;
                              // }

                              // console.log('IS IT UK:', isUK);

                              const urlParams = Object.keys(this.props.search)
                                .filter((key) => !this.state.clearedParams.includes(key))
                                .reduce((result, key) => {
                                  result[key] = this.props.search[key];
                                  return result;
                                }, {});

                              // START HERE
                              // Handle UK specific parameters
                              if (isUK) {
                                const uklocvalue = this.state.uklocvalue?.Postcode;
                                const activity = this.state.activity || this.state.activityInput;
                                delete urlParams.c;
                                delete urlParams.w;
                                if (uklocvalue) {
                                  urlParams.l = uklocvalue;
                                } else if (
                                  this.props.toggle === 'People' &&
                                  this.state.ukInputValue
                                ) {
                                  urlParams.l = this.state.ukInputValue;
                                }
                                if (this.state.activity) {
                                  urlParams.a = this.state.activity;
                                  delete urlParams.ac;
                                }
                                if (
                                  urlParams.a &&
                                  this.state.activityval &&
                                  Object.keys(this.state.activityval).length === 1
                                ) {
                                  urlParams.ac = this.state.activityInput;
                                  delete urlParams.a;
                                }

                                if (
                                  !urlParams.a &&
                                  !this.state.activity &&
                                  this.state.activityInput
                                ) {
                                  urlParams.ac = this.state.activityInput;
                                  delete urlParams.a;
                                }
                                if (this.state.radius?.m) {
                                  urlParams.rad = this.state.radius.m;
                                }
                                if (typeof this.props.UpdateState === 'function') {
                                  this.props.UpdateState({upgrade: false});
                                }
                              }
                              // Handle non-UK specific parameters
                              else {
                                const countryCode = this.state.country?.code;
                                const worldlocvalue = this.state.worldlocvalue?.geoname_id;
                                const {activityInput} = this.state;
                                const worldradius = this.state.worldradius?.index;
                                delete urlParams.l;
                                delete urlParams.a;
                                delete urlParams.t;
                                delete urlParams.e;
                                delete urlParams.tu;
                                delete urlParams.i;
                                if (countryCode) {
                                  urlParams.c = countryCode;
                                }
                                if (worldlocvalue) {
                                  urlParams.w = worldlocvalue;
                                }
                                if (activityInput) {
                                  urlParams.ac = activityInput;
                                }
                                if (worldradius) {
                                  urlParams.rad = worldradius;
                                }
                              }

                              // Convert the urlParams object to a URL query string
                              const queryParams = [];
                              Object.keys(urlParams).forEach((key) => {
                                queryParams.push(`${key}=${encodeURIComponent(urlParams[key])}`);
                              });
                              const queryString = queryParams.join('&');
                              const url = `/leadsearch?${queryString}`;

                              this.setState({
                                opencountry: false,
                                openlocation: false,
                                openworldlocation: false,
                                openradius: false,
                                openactivity: false,
                                openjobtitle: false,
                              });

                              if (typeof window !== 'undefined') {
                                const currentUrl =
                                  window.location.pathname + window.location.search;
                                // console.log(
                                //   'Current URL:',
                                //   currentUrl,
                                //   url,
                                // );
                                if (url === currentUrl) {
                                  toast.error("Your filters haven't changed");
                                  this.setState({searching: false});
                                  if (typeof this.props.UpdateState === 'function') {
                                    this.props.UpdateState({
                                      loading: false,
                                      countload: false,
                                    });
                                  }
                                  return;
                                } else {
                                  this.props.navigate(url, {
                                    state: {
                                      ...this.state,
                                      searching: true,
                                      loading: true,
                                      opencountry: false,
                                      openlocation: false,
                                      openworldlocation: false,
                                      openradius: false,
                                      openactivity: false,
                                      openjobtitle: false,
                                      next_page_token: null,
                                    },
                                  });
                                }
                              }

                              // Navigate to the dynamically constructed URL
                            }}
                            sx={{
                              position: 'absolute', // Position the button absolutely within its relative parent
                              right: '0px', // Adjust this value to control the button's position from the right
                              top: '50%', // Center the button vertically relative to its parent
                              transform: 'translateY(-50%)', // Adjust vertical positioning to centre the button
                              transition: 'all 0.3s ease',
                              borderRadius: selected ? '32px' : '50%',
                              width: selected ? '111.75px' : '48px',
                              height: '48px',
                              padding: 0,
                              backgroundColor: '#003671',
                              color: 'white',
                              marginRight: '10px',
                              minWidth: '0',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              '&:hover': {
                                backgroundColor: '#194f8a !important',
                              },
                            }}>
                            {shouldShowCircularProgress && (
                              <CircularProgress
                                sx={{
                                  '& .MuiCircularProgress-circle': {
                                    animationDuration: '4s', // Adjust this value as needed to control the speed
                                  },
                                  color: 'white',
                                  margin: '0 !important',
                                  pointerEvents: 'none',
                                }}
                              />
                            )}
                            {!selected && !this.state.searching && (
                              <SearchIcon
                                sx={{
                                  color: 'white',
                                  margin: '0 !important',
                                  pointerEvents: 'none',
                                }}
                              />
                            )}
                            {selected && !this.props.loading && (
                              <>
                                <SearchIcon
                                  sx={{
                                    color: 'white',
                                    margin: '0 0px 0 0 !important',
                                    pointerEvents: 'none',
                                  }}
                                />
                                <span
                                  style={{
                                    pointerEvents: 'none',
                                    opacity: selected ? 1 : 0, // Toggle opacity based on selected state
                                    marginLeft: '0px',
                                    textTransform: 'capitalize',
                                    fontWeight: '500',
                                    fontSize: '15px',
                                    paddingLeft: '3px',
                                    paddingRight: '6px',
                                    maxWidth: selected ? '100px' : '0', // Toggle max-width to control the appearance of the text
                                    overflow: 'hidden',
                                    transition: 'opacity 0.3s ease, max-width 0.3s ease', // Add transition for opacity and max-width
                                  }}>
                                  Search
                                </span>
                              </>
                            )}
                          </Button>
                        </Tooltip>
                      </Hidden>
                      {/* </div> */}
                    </Grid>
                  </Grid>
                  {/* </Hidden> */}
                </Toolbar>
              ) : (
                <Toolbar
                  component={Paper}
                  elevation={1}
                  sx={{
                    justifyContent: 'space-between',

                    padding: '0px !important',
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid rgb(221, 221, 221)',
                    borderRadius: '32px',
                    boxShadow: !selected
                      ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
                      : 'none',
                    backgroundColor:
                      this.state.opencountry ||
                      this.state.openlocation ||
                      this.state.openradius ||
                      this.state.openjobtitle ||
                      this.state.openworldlocation ||
                      this.state.openactivity
                        ? '#ebebeb'
                        : '#ffffff',
                  }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    // spacing={2} // Adds spacing between the elements
                    sx={{flexWrap: 'nowrap'}}>
                    <ClickAwayListener
                      onClickAway={(event) => {
                        if (this.state.selectingOption && this.state.opencountry) {
                          this.handleLabel(event, 'opencountry', false);
                        }
                      }}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        style={{padding: '0px'}}
                        sx={{
                          position: 'relative',

                          '&::after': {
                            content: {sm: '""'},
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            height: '30px',
                            width: {sm: '1px'},
                            backgroundColor: (theme) =>
                              this.state.opencountry ||
                              this.state.openlocation ||
                              this.state.openworldlocation ||
                              this.state.openjobtitle ||
                              ['country', 'location', 'worldlocation'].includes(this.state.hovered)
                                ? 'transparent'
                                : ['country', 'location', 'worldlocation'].includes(
                                    this.state.hovered
                                  )
                                ? 'white'
                                : 'divider',
                            display: {xs: 'none', sm: 'block'},
                          },

                          '&::before': {
                            content: {xs: '""', sm: 'none'},
                            position: 'absolute',
                            bottom: 0,
                            left: '24px',
                            width: 'calc(100% - 48px)',
                            height: {xs: '1px'},
                            backgroundColor: (theme) =>
                              this.state.opencountry ||
                              this.state.openjobtitle ||
                              this.state.openlocation ||
                              this.state.openworldlocation ||
                              ['country', 'location', 'worldlocation'].includes(this.state.hovered)
                                ? 'transparent'
                                : ['country', 'location', 'worldlocation'].includes(
                                    this.state.hovered
                                  )
                                ? 'white'
                                : 'divider',

                            display: {xs: 'block', sm: 'none'},
                          },
                        }}>
                        <this.CountryAutocomplete isMobile={false} />
                      </Grid>
                    </ClickAwayListener>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{padding: '0px'}}
                      sx={{
                        position: 'relative',
                        padding: '0px',

                        '&::after': {
                          content: {sm: '""'},
                          position: 'absolute',
                          right: 0,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          height: '30px',
                          width: {sm: '1px'},
                          backgroundColor: (theme) =>
                            this.state.openradius ||
                            this.state.openactivity ||
                            this.state.openlocation ||
                            this.state.openworldlocation ||
                            this.state.openjobtitle ||
                            ['jobtitle', 'location', 'worldlocation'].includes(this.state.hovered)
                              ? 'transparent'
                              : theme.palette.divider,
                          display: {xs: 'none', sm: 'block'},
                        },

                        '&::before': {
                          content: {xs: '""', sm: 'none'},
                          position: 'absolute',
                          bottom: 0,
                          left: '24px',
                          width: 'calc(100% - 48px)',
                          height: {xs: '1px'},
                          backgroundColor: (theme) =>
                            this.state.openradius ||
                            this.state.openjobtitle ||
                            this.state.openactivity ||
                            ['location', 'worldlocation'].includes(this.state.hovered)
                              ? 'transparent'
                              : theme.palette.divider,
                          display: {xs: 'block', sm: 'none'},
                        },
                        '&:last-of-type::after': {
                          content: 'none',
                        },
                      }}>
                      {this.state.inputCountry === 'United Kingdom' ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0,
                            paddingLeft: '0px',
                            minWidth: '25%',
                          }}>
                          <ClickAwayListener
                            onClickAway={(event) => {
                              if (this.state.selectingOption && this.state.openlocation) {
                                this.handleLabel(event, 'openlocation', false);
                              }
                            }}>
                            <this.LocationAutocomplete isMobile={false} />
                          </ClickAwayListener>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0,
                            paddingLeft: '0px',
                            minWidth: '34%',
                          }}>
                          <ClickAwayListener
                            onClickAway={(event) => {
                              if (!this.state.selectingOption && this.state.openworldlocation) {
                                this.handleLabel(event, 'openworldlocation', false);
                              }
                            }}>
                            <this.LocationAutocomplete isMobile={false} />
                          </ClickAwayListener>
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{padding: '0px'}}
                      sx={{
                        position: 'relative',
                        padding: '0px',
                        '&::before': {
                          content: {xs: '""', sm: 'none'},
                          position: 'absolute',
                          bottom: 0,
                          left: '24px',
                          width: 'calc(100% - 48px)',
                          height: {xs: '1px'},
                          backgroundColor: (theme) =>
                            this.state.openlocation ||
                            this.state.openworldlocation ||
                            this.state.openjobtitle ||
                            ['jobtitle', 'location', 'worldlocation'].includes(this.state.hovered)
                              ? 'transparent'
                              : theme.palette.divider,
                          display: {xs: 'block', sm: 'none'},
                        },
                        '&::after': {
                          content: {sm: '""'},
                          position: 'absolute',
                          right: 0,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          height: '30px',
                          width: {sm: '1px'},
                          backgroundColor: (theme) =>
                            this.state.openactivity ||
                            this.state.openjobtitle ||
                            ['jobtitle', 'activity'].includes(this.state.hovered)
                              ? 'transparent'
                              : theme.palette.divider,
                          display: {xs: 'none', sm: 'block'},
                        },
                      }}>
                      <ClickAwayListener
                        onClickAway={(event) => {
                          // console.log('CLICK AWAY!', this.state.selectingOption, this.state.openjobtitle);
                          // if (this.state.selectingOption && this.state.openjobtitle) {
                          if (this.state.openjobtitle) {
                            // console.log('CLICK AWAY 1');
                            this.handleLabel(event, 'openjobtitle', false);
                          }
                        }}>
                        <Autocomplete
                          freeSolo
                          disableClearable
                          onMouseEnter={() => this.setState({hovered: 'jobtitle'})}
                          onMouseLeave={() => this.setState({hovered: undefined})}
                          id="leadsearch-job-title"
                          open={this.state.openjobtitle}
                          onOpen={() => {
                            this.setState({
                              openjobtitle: true,
                              openradius: false,
                              openlocation: false,
                              openworldlocation: false,
                              opencountry: false,
                              openactivity: false,
                              selectingOption: true,
                            });
                            // console.log('OPEN JOB TITLE', this.state.selectingOption, this.state.openjobtitle);
                          }}
                          inputValue={this.state.jobTitleInputValue}
                          onInputChange={(event, newInputValue) => {
                            this.setState({jobTitleInputValue: newInputValue});
                          }}
                          value={this.state.jobTitle}
                          onChange={(event, newValue) => {
                            this.setState({
                              jobTitle: newValue?.label || newValue || '',
                              jobTitleInputValue: newValue?.label || newValue || '',
                              openjobtitle: false,
                            });
                          }}
                          filterOptions={(options, {inputValue}) => {
                            const filtered = options.filter((option) =>
                              option.label.toLowerCase().includes(inputValue.toLowerCase())
                            );

                            // Suggest the user's input as an additional option if not already present
                            if (
                              inputValue !== '' &&
                              !options.some(
                                (option) => option.label.toLowerCase() === inputValue.toLowerCase()
                              )
                            ) {
                              filtered.push({label: inputValue});
                            }

                            return filtered;
                          }}
                          ListboxProps={{
                            onMouseEnter: () => this.setState({mouseInListBox: true}),
                            onMouseLeave: () => this.setState({mouseInListBox: false}),
                            sx: {
                              '&& li.Mui-focused': {
                                bgcolor: !this.state.mouseInListBox ? 'transparent' : 'transparent',
                              },
                            },
                          }}
                          options={this.state.jobTitleOptions}
                          getOptionLabel={(option) => option.label || ''}
                          style={{
                            backgroundColor: this.state.openjobtitle ? '#ffffff' : 'inherit',
                            borderRadius: '32px',
                            boxShadow: this.state.openjobtitle
                              ? '0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)'
                              : 'none',
                          }}
                          PaperComponent={({children}) => (
                            <Paper
                              style={{
                                borderRadius: '32px',
                                marginTop: '12px',
                                padding: '8px',
                              }}
                              elevation={1}>
                              {children}
                            </Paper>
                          )}
                          renderOption={(props, option, {selected}) => (
                            <Box
                              style={{boxShadow: 'none'}}
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#ebebeb !important',
                                  borderRadius: '32px !important',
                                },
                                '&[aria-selected="true"]': {
                                  backgroundColor: '#ebebeb !important',
                                  borderRadius: '32px !important',
                                },
                                display: 'flex',
                                justifyContent: 'flex-start !important',
                                paddingRight: '4px',
                                alignItems: 'center',
                                textDecoration: selected ? 'underline' : 'none',
                                '& > img': {
                                  marginRight: '5px',
                                  flexShrink: 0,
                                },
                              }}
                              {...props}>
                              <span>{option.label}</span>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <div style={{position: 'relative'}}>
                              <TextField
                                {...params}
                                label="Job Title"
                                variant="outlined"
                                placeholder="Choose title"
                                InputLabelProps={{
                                  ...params.InputLabelProps,
                                  shrink: true,
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                  style: {
                                    paddingRight: '20px',
                                    paddingLeft: '20px',
                                    paddingTop: '23px',
                                  },
                                  endAdornment: null,
                                  inputProps: {
                                    ...params.inputProps,
                                    style: {fontSize: '0.9rem'},
                                  },
                                }}
                                sx={{
                                  '& fieldset': {border: 'none'},
                                  '& .MuiOutlinedInput-root': {
                                    border: 'none',
                                    borderRadius: '32px',
                                    height: '66px',
                                    cursor: 'pointer !important',
                                    borderColor: 'transparent',
                                    '&:hover': {
                                      backgroundColor: this.state.openjobtitle
                                        ? 'white'
                                        : this.state.opencountry ||
                                          this.state.openlocation ||
                                          this.state.openworldlocation ||
                                          this.state.openactivity
                                        ? '#dddddd'
                                        : '#ebebeb',
                                      cursor: 'pointer !important',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'transparent',
                                      cursor: 'pointer !important',
                                    },
                                  },
                                  '& .MuiInputLabel-outlined': {
                                    transform: 'translate(24px, 13px) scale(1)',
                                    fontWeight: '600',
                                    fontSize: '16.2px',
                                    paddingRight: '20px',
                                    color: '#000000',
                                    cursor: 'pointer !important',
                                    '&.MuiInputLabel-shrink': {
                                      transform: 'translate(24px, 13px) scale(0.75)',
                                      cursor: 'pointer !important',
                                    },
                                    pointerEvents: 'none',
                                  },
                                }}
                              />
                              {/* {this.state.jobTitleInputValue && (
                                <Tooltip title="Clear job title" placement="top">
                                  <IconButton
                                    sx={{
                                      position: 'absolute',
                                      left: '74px',
                                      top: '32%',
                                      fontSize: '12px',
                                      transform: 'translateY(-50%)',
                                      padding: '0px 8px',
                                      '&:hover': {
                                        color: '#f40057',
                                        backgroundColor: 'transparent',
                                      },
                                    }}
                                    onClick={() => {
                                      this.setState({jobTitleInputValue: '', jobTitle: ''});
                                    }}>
                                    <CancelIcon
                                      sx={{
                                        fontSize: '12px !important',
                                        color: 'inherit',
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )} */}
                            </div>
                          )}
                        />
                      </ClickAwayListener>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{padding: '0px', position: 'relative'}}>
                      <ClickAwayListener
                        onClickAway={(event) => {
                          if (this.state.selectingOption && this.state.openactivity) {
                            this.handleLabel(event, 'openactivity', false);
                          }
                        }}>
                        <this.BusinessAutocomplete isMobile={false} />
                      </ClickAwayListener>

                      <Hidden mdDown>
                        <Tooltip title="Fetch a page of results for 1 credit" placement="bottom">
                          <Button
                            type="submit"
                            aria-label="search"
                            disabled={this.state.searching}
                            // onClick={(event) => {
                            //   event.preventDefault();
                            //   this.setState({searching: true});

                            //   if (typeof this.props.UpdateState === 'function') {
                            //     this.props.UpdateState({
                            //       loading: true,
                            //       countload: true,
                            //     });
                            //   }

                            //   const isUK = this.state.inputCountry === 'United Kingdom';
                            //   const urlParams = Object.keys(this.props.search)
                            //     .filter((key) => !this.state.clearedParams.includes(key))
                            //     .reduce((result, key) => {
                            //       result[key] = this.props.search[key];
                            //       return result;
                            //     }, {});

                            //   if (isUK) {
                            //     const uklocvalue = this.state.uklocvalue?.Postcode;
                            //     const activity = this.state.activity || this.state.activityInput;
                            //     delete urlParams.c;
                            //     delete urlParams.w;
                            //     if (uklocvalue) {
                            //       urlParams.l = uklocvalue;
                            //     }
                            //     if (this.state.activity) {
                            //       urlParams.a = this.state.activity;
                            //       delete urlParams.ac;
                            //     }
                            //     if (
                            //       !urlParams.a &&
                            //       !this.state.activity &&
                            //       this.state.activityInput
                            //     ) {
                            //       urlParams.ac = this.state.activityInput;
                            //       delete urlParams.a;
                            //     }
                            //     if (this.state.radius?.m) {
                            //       urlParams.rad = this.state.radius.m;
                            //     }
                            //     if (typeof this.props.UpdateState === 'function') {
                            //       this.props.UpdateState({upgrade: false});
                            //     }
                            //   } else {
                            //     const countryCode = this.state.country?.code;
                            //     const worldlocvalue = this.state.worldlocvalue?.geoname_id;
                            //     const {activityInput} = this.state;
                            //     const worldradius = this.state.worldradius?.index;
                            //     delete urlParams.l;
                            //     delete urlParams.a;
                            //     delete urlParams.t;
                            //     delete urlParams.e;
                            //     delete urlParams.tu;
                            //     delete urlParams.i;
                            //     if (countryCode) {
                            //       urlParams.c = countryCode;
                            //     }
                            //     if (worldlocvalue) {
                            //       urlParams.w = worldlocvalue;
                            //     }
                            //     if (activityInput) {
                            //       urlParams.ac = activityInput;
                            //     }
                            //     if (worldradius) {
                            //       urlParams.rad = worldradius;
                            //     }
                            //   }

                            //   const queryParams = [];
                            //   Object.keys(urlParams).forEach((key) => {
                            //     queryParams.push(`${key}=${encodeURIComponent(urlParams[key])}`);
                            //   });
                            //   const queryString = queryParams.join('&');
                            //   const url = `/leadsearch/?${queryString}`;

                            //   this.setState({
                            //     opencountry: false,
                            //     openlocation: false,
                            //     openworldlocation: false,
                            //     openradius: false,
                            //     openactivity: false,
                            //   });

                            //   if (typeof window !== 'undefined') {
                            //     const currentUrl = window.location.pathname + window.location.search;
                            //     if (url === currentUrl) {
                            //       toast.error("Your filters haven't changed");
                            //       this.setState({searching: false});
                            //       if (typeof this.props.UpdateState === 'function') {
                            //         this.props.UpdateState({
                            //           loading: false,
                            //           countload: false,
                            //         });
                            //       }
                            //       return;
                            //     } else {
                            //       this.props.navigate(url, {
                            //         state: {
                            //           ...this.state,
                            //           searching: true,
                            //           loading: true,
                            //           opencountry: false,
                            //           openlocation: false,
                            //           openworldlocation: false,
                            //           openradius: false,
                            //           openactivity: false,
                            //         },
                            //       });
                            //     }
                            //   }
                            // }}
                            onClick={(event) => {
                              // PEOPLE
                              event.preventDefault();
                              this.setState({searching: true});

                              if (typeof this.props.UpdateState === 'function') {
                                this.props.UpdateState({
                                  loading: true,
                                  countload: true,
                                });
                              }

                              let isUK = this.state.inputCountry === 'United Kingdom';
                              const urlParams = {};

                              if (
                                this.state.activityval &&
                                Object.keys(this.state.activityval).length === 1
                              ) {
                                isUK = false;
                              }
                              // console.log('IS IT UK 1:', isUK);

                              // Handle the People toggle
                              urlParams.bar =
                                this.state.toggle === 'People' ? 'people' : 'companies';

                              // Handle jobTitle
                              const jobTitle = this.state.jobTitleInputValue || this.state.jobTitle;
                              if (jobTitle) {
                                urlParams.jt = jobTitle;
                              }

                              if (isUK) {
                                const uklocvalue = this.state.uklocvalue?.Postcode;
                                const activity = this.state.activity || this.state.activityInput;
                                if (uklocvalue) {
                                  urlParams.l = uklocvalue;
                                } else if (
                                  this.props.toggle === 'People' &&
                                  this.state.ukInputValue
                                ) {
                                  urlParams.l = this.state.ukInputValue;
                                }
                                if (this.state.activity) {
                                  urlParams.a = this.state.activity;
                                } else if (this.state.activityInput) {
                                  urlParams.ac = this.state.activityInput;
                                }
                                if (this.state.radius?.m) {
                                  urlParams.rad = this.state.radius.m;
                                }
                              } else {
                                const countryCode = this.state.country?.code;
                                const worldlocvalue = this.state.worldlocvalue?.geoname_id;
                                const {activityInput} = this.state;
                                const worldradius = this.state.worldradius?.index;

                                if (countryCode) {
                                  urlParams.c = countryCode;
                                }
                                if (worldlocvalue) {
                                  urlParams.w = worldlocvalue;
                                }
                                if (activityInput) {
                                  urlParams.ac = activityInput;
                                }
                                if (worldradius) {
                                  urlParams.rad = worldradius;
                                }
                              }

                              // Debugging statements
                              // console.log('URL Parameters:', urlParams);

                              const queryParams = Object.keys(urlParams).map(
                                (key) => `${key}=${encodeURIComponent(urlParams[key])}`
                              );
                              const queryString = queryParams.join('&');
                              const url = `/leadsearch?${queryString}`;

                              // console.log('Constructed URL:', url);

                              this.setState({
                                opencountry: false,
                                openlocation: false,
                                openworldlocation: false,
                                openradius: false,
                                openactivity: false,
                                openjobtitle: false,
                              });

                              if (typeof window !== 'undefined') {
                                const currentUrl =
                                  window.location.pathname + window.location.search;
                                // console.log('Current URL 1:', currentUrl, url);
                                if (url === currentUrl) {
                                  toast.error("Your filters haven't changed");
                                  this.setState({searching: false});
                                  if (typeof this.props.UpdateState === 'function') {
                                    this.props.UpdateState({
                                      loading: false,
                                      countload: false,
                                    });
                                  }
                                  return;
                                } else {
                                  this.props.navigate(url, {
                                    state: {
                                      ...this.state,
                                      searching: true,
                                      loading: true,
                                      opencountry: false,
                                      openlocation: false,
                                      openworldlocation: false,
                                      openradius: false,
                                      openactivity: false,
                                      openjobtitle: false,
                                    },
                                  });
                                }
                              }
                            }}
                            sx={{
                              position: 'absolute',
                              right: '0px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              transition: 'all 0.3s ease',
                              borderRadius: selected ? '32px' : '50%',
                              width: selected ? '111.75px' : '48px',
                              height: '48px',
                              padding: 0,
                              backgroundColor: '#003671',
                              color: 'white',
                              marginRight: '10px',
                              minWidth: '0',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              '&:hover': {
                                backgroundColor: '#194f8a !important',
                              },
                            }}>
                            {shouldShowCircularProgress && (
                              <CircularProgress
                                sx={{
                                  '& .MuiCircularProgress-circle': {
                                    animationDuration: '4s',
                                  },
                                  color: 'white',
                                  margin: '0 !important',
                                  pointerEvents: 'none',
                                }}
                              />
                            )}
                            {!selected && !this.state.searching && (
                              <SearchIcon
                                sx={{
                                  color: 'white',
                                  margin: '0 !important',
                                  pointerEvents: 'none',
                                }}
                              />
                            )}
                            {selected && !this.props.loading && (
                              <>
                                <SearchIcon
                                  sx={{
                                    color: 'white',
                                    margin: '0 0px 0 0 !important',
                                    pointerEvents: 'none',
                                  }}
                                />
                                <span
                                  style={{
                                    pointerEvents: 'none',
                                    opacity: selected ? 1 : 0,
                                    marginLeft: '0px',
                                    textTransform: 'capitalize',
                                    fontWeight: '500',
                                    fontSize: '15px',
                                    paddingLeft: '3px',
                                    paddingRight: '6px',
                                    maxWidth: selected ? '100px' : '0',
                                    overflow: 'hidden',
                                    transition: 'opacity 0.3s ease, max-width 0.3s ease',
                                  }}>
                                  Search
                                </span>
                              </>
                            )}
                          </Button>
                        </Tooltip>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Toolbar>
              )}
            </AppBar>
            {/* <Grid item xs={12} sm={4} style={{display: 'flex', justifyContent: 'center'}}> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '0px', // Adjust this value if your AppBar height is different
                width: '100%',
              }}>
              <ToggleButtonGroup
                value={this.state.toggle}
                key={this.state.toggle}
                exclusive
                onChange={() => {
                  const url = new URL(window.location);
                  if (this.state.toggle === 'People') {
                    this.setState({toggle: 'Companies'});
                    if (typeof this.props.UpdateState === 'function') {
                      this.props.UpdateState({bar: null});
                    }
                    url.searchParams.delete('p');
                    url.searchParams.delete('bar');
                  } else {
                    this.setState({toggle: 'People'});
                    if (typeof this.props.UpdateState === 'function') {
                      this.props.UpdateState({bar: 'People'});
                    }
                    url.searchParams.delete('p');
                    url.searchParams.set('bar', 'people');
                  }
                  this.props.navigate(url.pathname + url.search);
                }}
                sx={{
                  backgroundColor: '#F0F0F0', // The background color of the whole group
                  padding: '4px',
                  borderRadius: '0px 0px 24px 24px', // The border-radius for the whole group
                  '.MuiToggleButton-root': {
                    // Targets the individual toggle buttons
                    border: 'none', // Removes individual borders
                    backgroundColor: 'transparent', // Resets the background to be transparent
                    // borderRadius: '0px 0px 24px 24px', // Applies border radius to individual buttons
                    // margin: '-4px 4px 0px',
                    marginTop: '-4px',
                    borderRadius: '24px',
                    '&.Mui-selected': {
                      // Styles for the selected state
                      color: 'white', // Text color for the selected state
                      backgroundColor: '#003671', // Background color for the selected state
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Adds shadow to the selected state
                      '&:hover': {
                        backgroundColor: '#003671', // Background color on hover when selected
                      },
                    },
                    '&:not(.Mui-selected):hover': {
                      // Styles for the hover state when not selected
                      // backgroundColor: '#0056b3', // Background color on hover
                      '@media (hover: none)': {
                        backgroundColor: 'transparent', // Resets on devices that do not differentiate hover
                      },
                    },
                    // '&:hover': {
                    //   // Styles for the hover state
                    //   // backgroundColor: '#0056b3', // Background color on hover
                    //   // '@media (hover: none)': {
                    //   //   backgroundColor: 'transparent', // Resets on devices that do not differentiate hover
                    //   // },
                    // },
                  },
                  '.MuiToggleButtonGroup-grouped': {
                    // Applies styles to buttons when grouped
                    minWidth: '148px', // Minimum width for the buttons
                    '&:not(:first-of-type)': {
                      borderRadius: '0px 0px 24px 24px', // Applies border radius to individual buttons
                      // marginLeft: '0px', // Adjusts the spacing between buttons
                    },
                    '&:first-of-type': {
                      borderRadius: '0px 0px 24px 24px',
                      borderTopRightRadius: '0px', // Removes top-right border-radius for the first button
                      borderBottomRightRadius: '24px', // Removes bottom-right border-radius for the first button
                    },
                    '&:last-of-type': {
                      borderRadius: '0px 0px 24px 24px',
                      borderTopLeftRadius: '0px', // Removes top-left border-radius for the last button
                      borderBottomLeftRadius: '24px', // Removes bottom-left border-radius for the last button
                    },
                  },
                }}
                aria-label="text alignment">
                <ToggleButton value="Companies">
                  <Typography style={{textTransform: 'capitalize'}} variant="caption">
                    Companies
                  </Typography>
                </ToggleButton>
                <ToggleButton value="People">
                  <Typography style={{textTransform: 'capitalize'}} variant="caption">
                    People
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Hidden>
        </div>
      </ClickAwayListener>
    );
  }
}

LeadsearchBar.defaultProps = {
  // profile: PropTypes.any,
  items: PropTypes.any,
  navigate: PropTypes.any,
};

LeadsearchBar.propTypes = {
  // profile: PropTypes.any,
  items: PropTypes.any,
  navigate: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeadsearchBar));
